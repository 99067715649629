import React, { useEffect } from 'react'
import { Grid, Row, Col, Button, Typography } from 'antd'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { BlogPageContainer, FeedbackBox } from './styled'
import { ReactComponent as IconFeedbackBox } from '../../../assets/images/iconFeedbackBox.svg'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse, PaginationDots } from '../../components'
import BlogItemsList from '../../components/BlogItemsList'
import GillHeader from '../GillSteelPage/components/GillHeader'

const { useBreakpoint } = Grid

const BlogPage = ({ getBlogPosts }) => {
  const screens = useBreakpoint()

  const { isFetchingBlogPosts, all } = useShallowEqualSelector((state) => state.blogPosts)

  useEffect(() => {
    const parseParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
    getBlogPosts(parseParams)
  }, [])

  useDocumentTitle('Monthly Digest')

  return (
    <BlogPageContainer.Wrapper>
      <GillHeader title='Monthly Digest' />
      <BlogPageContainer>
        <BlogPageContainer.CircleGreen>
          <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
        </BlogPageContainer.CircleGreen>

        <BlogPageContainer.SemiRing>
          <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
        </BlogPageContainer.SemiRing>

        <BlogPageContainer.SemiCircle>
          <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
        </BlogPageContainer.SemiCircle>

        <LayoutCentering>
          <Row gutter={[32, 32]} wrap={!screens.xl}>
            {screens.xl && (
              <Col {...(screens.xl ? { flex: '292px' } : { xs: 24 })}>
                {screens.xl && (
                  <FeedbackBox>
                    <FeedbackBox.Header>
                      <IconFeedbackBox />
                      <p>Have any feedback?</p>
                    </FeedbackBox.Header>
                    <FeedbackBox.Content>
                      We hope the Estate Research Learning Hub proves a useful resource for Private Client Practitioners
                      and would love to hear your ideas of how we can continuously improve.
                    </FeedbackBox.Content>
                    <Link to='/feedback'>
                      <Button type='primary' ghost block>
                        Give Feedback
                      </Button>
                    </Link>
                  </FeedbackBox>
                )}
              </Col>
            )}

            <Col {...(screens.xl ? { flex: 'auto' } : { xs: 24 })}>
              <Typography style={{ padding: '0 15px', fontSize: 14, marginBottom: 40 }}>
                <Typography.Paragraph strong style={{ fontSize: 16 }}>
                  The Monthly Digest offers an easy way to keep up to date with pressing topics in Wills, Probate,
                  Elderly and vulnerable client law and Tax and Trusts, with quality insights from the award-winning,
                  experienced and trusted solicitor - Gill Steel.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  Created for practitioners in England & Wales the Monthly Digest is designed for those who find it
                  challenging and expensive to stay on top of changes in relevant law and practice. Each document offers
                  an informed summary of recent developments and cases that provide practical pointers on the effect on
                  your practice, with sources for further research on how to apply the law to individual client
                  circumstances.
                </Typography.Paragraph>

                <Typography.Paragraph>Click to open each Monthly Digest below.</Typography.Paragraph>
              </Typography>

              <BlogPageContainer.List>
                <BlogItemsList loading={isFetchingBlogPosts} dataSource={all.list} />
                <PaginationDots onRequest={getBlogPosts} meta={all.meta} isScrollToTop />
              </BlogPageContainer.List>
            </Col>
          </Row>
        </LayoutCentering>
      </BlogPageContainer>
    </BlogPageContainer.Wrapper>
  )
}

export default BlogPage
