import { Image } from 'antd'
import styled from 'styled-components'
import { media } from '../../../../../styles'

export const StyledContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray[200]};
  padding: 10rem 0;

  ${media.xl`
    padding: 2.8rem 0;
  `}
`

export const StyledLogo = styled(Image)`
  height: 50px;
  width: auto;

  ${media.lg`
    height: 35px;
  `}
`

export const StyledContent = styled.div`
  padding: 0 16px;
  max-width: 1280px;
  margin: 0 auto;
`

export const StyledCopy = styled.p`
  color: ${({ theme }) => theme.colors.blue[200]};
  margin-top: 4.5rem;
  margin-bottom: 0;

  ${media.lg`
    margin-top: 2.4rem;
  `}

  a {
    font-weight: 700;
  }
`

export const StyledBox = styled.div`
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.grayBlue[200]}`};
  background: ${({ theme }) => theme.colors.gray[100]};
  padding: 3rem 3rem 2rem;
  margin-bottom: 0;
  height: 100%;

  ${media.xl`
    padding: 2.4rem;
    margin-bottom: 20px;
    height: auto;
  `}
`
