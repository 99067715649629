import React from 'react'
import { Typography } from 'antd'
import Lottie from 'react-lottie'
import { Message } from './styled'

const { Title, Text } = Typography

const MessageProcess = ({ picture, animationData, title, details }) => (
  <Message>
    {picture && <Message.Picture src={picture} />}

    {animationData && (
      <Lottie
        height={170}
        options={{
          loop: true,
          autoplay: true,
          animationData,
        }}
      />
    )}

    <Title level={2}>{title}</Title>
    <Text>{details}</Text>
  </Message>
)

export default MessageProcess
