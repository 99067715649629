import React, { useCallback } from 'react'
import AccountHistory from './AccountHistory'
import { useActions } from '../../../../../hooks'
import { history as historyActions } from '../../../../../store/actions'

const AccountHistoryContainer = ({ ...rest }) => {
  const [historyDispatch] = useActions([historyActions])
  const getHistoryLog = useCallback(historyDispatch.getHistoryLog, [historyDispatch])
  const getHistoryCPDLog = useCallback(historyDispatch.getHistoryCPDLog, [historyDispatch])

  const props = {
    getHistoryLog,
    getHistoryCPDLog,
    ...rest,
  }

  return <AccountHistory {...props} />
}

export default AccountHistoryContainer
