import { counter as consts } from '../constants'

const initialState = {
  isFetchingCounterStats: false,
  counterStats: {},
}

// eslint-disable-next-line default-param-last
const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCHING_COUNTER_STATS: {
      return {
        ...state,
        isFetchingCounterStats: action.payload,
      }
    }

    case consts.RECEIVE_COUNTER_STATS: {
      return {
        ...state,
        isFetchingCounterStats: false,
        counterStats: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default counterReducer
