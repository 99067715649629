import { message } from 'antd'
import axios from 'axios'
import { storage } from '../helpers'

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

api.interceptors.request.use((config) => {
  const tokenJWT = storage.getItem('token')

  if (tokenJWT) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${tokenJWT}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    // const { data } = response

    // message.success(data.message)

    return response
  },
  (error) => {
    const { response } = error

    if (response.status === 401 && window.location.pathname !== '/login') {
      storage.removeItem('token')
      message.error(response.data.message)
      window.location.replace('/login')
    } else {
      try {
        if (
          response.data.code === 'app_token_expired' ||
          response.data.code === 'token_invalid' ||
          response.data.code === 'auth_data_invalid'
        ) {
          storage.removeItem('token')

          window.location.replace('/login')
          // eslint-disable-next-line no-param-reassign
          error = null
        } else {
          // eslint-disable-next-line no-unused-expressions
          response.status === 404 && window.location.replace('/404')
          message.error(response.data.message)
        }
      } catch (error_) {
        console.log(error_)
      }
    }

    return Promise.reject(error)
  },
)

// eslint-disable-next-line import/prefer-default-export
export { api }
