import { List as ListLibrary } from 'antd'
import styled from 'styled-components'
import { media } from '../../../styles'

const List = styled(ListLibrary)`
  position: relative;
  z-index: 1;
`

const BlogPostItem = styled.a`
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.grayBlue[200]};
  border-radius: 8px;
  padding: 28px 32px 24px;
  transition: all 0.3s ease;
  display: block;

  &:not(:last-child) {
    margin-bottom: 3.2rem;
  }

  ${media.lg`
    padding: 2.4rem;

  &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  `}

  &:hover {
    box-shadow: 0px 40px 160px -48px rgba(0, 0, 0, 0.25);
  }
`

BlogPostItem.Date = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin: 0;

  .anticon {
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.colors.red[200]};
    font-size: 1.6rem;
    margin-top: -3px;
  }
`

BlogPostItem.Title = styled.p`
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.blue[200]};
  margin: 2rem 0;
`

BlogPostItem.Excerpt = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 1.4rem
  line-height: 1.4;
  color:  ${({ theme }) => theme.colors.gray[600]};
`

BlogPostItem.CategoriesList = styled.div`
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;
`

BlogPostItem.CategoriesListItem = styled.div`
  background: ${({ theme }) => theme.colors.gray[400]};
  border-radius: 20px;
  padding: 6px 12px 4px;
  font-size: 10px;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[600]};
  margin: 4px;
`

export { List, BlogPostItem }
