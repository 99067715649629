import React from 'react'
import { Button, Grid } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Banner } from './styled'
import { theme } from '../../../../../styles'
import { Ellipse } from '../../../../components'

const { useBreakpoint } = Grid

const MainBanner = ({ title, details, isRegister }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  return (
    <Banner>
      <Banner.Circle>
        <Ellipse type='circle' color={theme.colors.green[200]} size={screens.lg ? 34 : 9.2} />
      </Banner.Circle>

      <Banner.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={8} rotate={-45} />
      </Banner.SemiCircle>

      <Banner.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={screens.lg ? 25 : 8} rotate={-45} />
      </Banner.SemiRing>

      <Banner.Inner>
        <Banner.Text>Welcome to the</Banner.Text>
        <Banner.Title>{title}</Banner.Title>
        <Banner.Text>{details}</Banner.Text>

        {isRegister && (
          <Button type='primary' onClick={() => navigate('/register')}>
            Create an account
          </Button>
        )}
      </Banner.Inner>
    </Banner>
  )
}

export default MainBanner
