import React, { useCallback } from 'react'
import MainFeaturedWebinars from './MainFeaturedWebinars'
import { useActions } from '../../../../../hooks'
import { webinars as webinarsActions } from '../../../../../store/actions'

const MainFeaturedWebinarsContainer = (rest) => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsRecorded = useCallback(webinarsDispatch.getWebinarsRecorded, [webinarsDispatch])

  const props = {
    getWebinarsRecorded,
    ...rest,
  }

  return <MainFeaturedWebinars {...props} />
}

export default MainFeaturedWebinarsContainer
