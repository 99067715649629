import React, { useCallback } from 'react'
import MainUpcomingWebinars from './MainUpcomingWebinars'
import { useActions } from '../../../../../hooks'
import { webinars as webinarsActions } from '../../../../../store/actions'

const MainUpcomingWebinarsContainer = () => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsUpcoming = useCallback(webinarsDispatch.getWebinarsUpcoming, [webinarsDispatch])

  const props = {
    getWebinarsUpcoming,
  }

  return <MainUpcomingWebinars {...props} />
}

export default MainUpcomingWebinarsContainer
