import React, { useEffect } from 'react'
import { Row, Col, Typography } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { AccountOverview, AccountProfile, AccountHistory, AccountLog } from './components'
import { Account, Menu } from './styled'
import { images } from '../../../assets'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse } from '../../components'

const { Title } = Typography

const AccountPage = ({ getSpeakersList, authLogout }) => {
  const params = useParams()

  useEffect(() => {
    getSpeakersList()
  }, [])

  const menuAccountList = [
    {
      name: 'Overview',
      to: '/account/overview',
      page: 'overview',
      icon: images.iconOverview,
    },
    {
      name: 'My Profile',
      to: '/account/profile',
      page: 'profile',
      icon: images.iconProfile,
    },
    {
      name: 'Learning Hub History',
      to: '/account/learning-hub-history',
      page: 'learning-hub-history',
      icon: images.iconHistory,
    },
    {
      name: 'CPD Log',
      to: '/account/cpd-log',
      page: 'cpd-log',
      icon: images.iconLog,
    },
    {
      name: 'Log out',
      handler: authLogout,
      icon: images.iconLogout,
    },
  ]

  return (
    <Account>
      <Account.CircleViolet>
        <Ellipse type='circle' color={theme.colors.violet[200]} size={15.3} />
      </Account.CircleViolet>

      <Account.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Account.SemiRing>

      <Account.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Account.SemiCircle>

      <LayoutCentering>
        <Account.Header>
          <Title level={2}>My account</Title>
        </Account.Header>

        <Row gutter={[32, 32]}>
          <Col xl={5} xs={24}>
            <Menu>
              {menuAccountList.map((item) => (
                <Menu.Item key={item.name} active={params.page === item.page}>
                  <Menu.Icon src={item.icon} />

                  {item.to ? (
                    <Link to={item.to}>{item.name}</Link>
                  ) : (
                    <Menu.Link onClick={item.handler}>{item.name}</Menu.Link>
                  )}
                </Menu.Item>
              ))}
            </Menu>
          </Col>

          <Col xl={19} xs={24}>
            {params.page === 'overview' && <AccountOverview />}

            {params.page === 'profile' && <AccountProfile />}

            {params.page === 'learning-hub-history' && <AccountHistory />}

            {params.page === 'cpd-log' && <AccountLog />}
          </Col>
        </Row>
      </LayoutCentering>
    </Account>
  )
}

export default AccountPage
