import React, { useCallback } from 'react'
import BlogPage from './BlogPage'
import { useActions } from '../../../hooks'
import { blogPosts as blogPostsActions } from '../../../store/actions'

const BlogPageContainer = () => {
  const [blogPostsDispatch] = useActions([blogPostsActions])
  const getBlogPosts = useCallback(blogPostsDispatch.getBlogPosts, [blogPostsDispatch])

  const props = {
    getBlogPosts,
  }

  return <BlogPage {...props} />
}

export default BlogPageContainer
