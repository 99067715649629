export const FETCHING_AUTH_LOGIN = 'FETCHING_AUTH_LOGIN'
export const RECEIVE_AUTH_LOGIN = 'RECEIVE_AUTH_LOGIN'
export const FAILED_AUTH_LOGIN = 'FAILED_AUTH_LOGIN'

export const FETCHING_AUTH_REGISTER = 'FETCHING_AUTH_REGISTER'
export const RECEIVE_AUTH_REGISTER = 'RECEIVE_AUTH_REGISTER'

export const FETCHING_AUTH_PROFILE_DETAILS = 'FETCHING_AUTH_PROFILE_DETAILS'
export const RECEIVE_AUTH_PROFILE_DETAILS = 'RECEIVE_AUTH_PROFILE_DETAILS'

export const FETCHING_UPDATE_PROFILE_DETAILS = 'FETCHING_UPDATE_PROFILE_DETAILS'
export const RECEIVE_UPDATE_PROFILE_DETAILS = 'RECEIVE_UPDATE_PROFILE_DETAILS'

export const FETCHING_UPDATE_PROFILE_PASSWORD = 'FETCHING_UPDATE_PROFILE_PASSWORD'
export const RECEIVE_UPDATE_PROFILE_PASSWORD = 'RECEIVE_UPDATE_PROFILE_PASSWORD'

export const FETCHING_AUTH_RESET_PASSWORD = 'FETCHING_AUTH_RESET_PASSWORD'
export const RECEIVE_AUTH_RESET_PASSWORD = 'RECEIVE_AUTH_RESET_PASSWORD'

export const FETCHING_AUTH_CHANGE_PASSWORD = 'FETCHING_AUTH_CHANGE_PASSWORD'
export const RECEIVE_AUTH_CHANGE_PASSWORD = 'RECEIVE_AUTH_CHANGE_PASSWORD'

export const FETCHING_AUTH_LOGOUT = 'FETCHING_AUTH_LOGOUT'

export const RESET_AUTH_LOGIN = 'RESET_AUTH_LOGIN'
export const RESET_FAILED_AUTH_LOGIN = 'RESET_FAILED_AUTH_LOGIN'
