import React, { useMemo, useState } from 'react'
import { Button, Col, Grid, Modal, Pagination, Row, Typography } from 'antd'
import { format } from 'date-fns'
import { Document, Page, pdfjs } from 'react-pdf'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'
import { images } from '../../../assets'
import { storage } from '../../../helpers'
import { ModalTitle } from '../ModalReferACase/styled'

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const { Paragraph } = Typography
const { useBreakpoint } = Grid

const Container = styled.div`
  background: #fff;
  border: 1px solid #dddee3;
  border-radius: 8px;
  padding: 20px;
`

const Body = styled.div`
  display: flex;
  align-items: center;
`

const StyledDocument = styled(Document)`
  canvas {
    width: 100% !important;
    max-width: 100%;
    height: auto !important;
    margin: 0 auto;
    overflow: hidden;
  }
`

const TypeIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ $type }) => {
    switch ($type) {
      case 'pdf': {
        return '#7CCDCA'
      }
      case 'document': {
        return '#76AED6'
      }
      case 'powerpoint': {
        return '#EE8175'
      }
      default: {
        return '#FFFFFF'
      }
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  font-size: 0;

  svg {
    width: 28px;
    height: 28px;
    stroke: #fff;
  }
`

const Attributes = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  color: #9a9cab;
  font-size: 10px;
  font-weight: 500;

  li {
    display: flex;
    align-items: center;
    gap: 4px;

    &:not(:last-child)::after {
      content: '';
      background: #eaeaea;
      width: 1px;
      height: 16px;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
`

const AttributeIcon = styled(ReactSVG)`
  font-size: 0;
  margin-top: -2px;

  svg {
    width: 16px;
    height: 16px;
    stroke: #9a9cab;
  }
`

const FileListItem = ({ file }) => {
  const screens = useBreakpoint()
  const [openModal, setOpenModal] = useState(false)
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)

  const options = useMemo(
    () => ({
      httpHeaders: { Authorization: `Bearer ${storage.getItem('token')}` },
    }),
    [],
  )

  return (
    <Container>
      <Row gutter={[16, 16]} align='middle' wrap={!screens.xl}>
        <Col {...(screens.xl ? { flex: '1' } : { xs: 24 })}>
          <Body>
            <TypeIcon $type={file.type}>
              {file.type === 'pdf' && <ReactSVG src={images.iconTablerFileTypePdf} />}
              {file.type === 'document' && <ReactSVG src={images.iconTablerFileTypeDoc} />}
              {file.type === 'powerpoint' && <ReactSVG src={images.iconTablerFileTypePpt} />}
            </TypeIcon>
            <div>
              <Paragraph
                style={{
                  color: '#132B4B',
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily: 'IBM Plex Sans',
                  marginBottom: 4,
                }}
              >
                {file.name}
              </Paragraph>
              <Attributes>
                <li>
                  <AttributeIcon src={images.iconTablerWorld} />
                  {file.size}
                </li>
                <li>
                  <AttributeIcon src={images.iconTablerCalendarEvent} /> {format(new Date(file.date), 'do MMMM yyyy')}
                </li>
              </Attributes>
            </div>
          </Body>
        </Col>
        <Col {...(screens.xl ? { flex: 'none' } : { xs: 24 })}>
          <Button
            type='primary'
            ghost
            block={!screens.xl}
            onClick={() => setOpenModal(true)}
            style={{ display: 'inline-flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}
          >
            Open <ReactSVG src={images.iconDownload} style={{ fontSize: 0 }} />
          </Button>
        </Col>
      </Row>

      <Modal
        title={<ModalTitle>{file.name}</ModalTitle>}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        centered
        footer={null}
        width={935}
        wrapClassName='ant-full-screen-on-mobile'
      >
        <Paragraph>
          <div dangerouslySetInnerHTML={{ __html: file.description }} />
        </Paragraph>
        <StyledDocument
          file={`${process.env.REACT_APP_BASE_URL}/files/${file.id}/content.pdf`}
          options={options}
          onLoadSuccess={(document) => setNumPages(document.numPages)}
        >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </StyledDocument>
        <Pagination
          simple
          pageSize={1}
          current={pageNumber}
          total={numPages}
          onChange={(page) => setPageNumber(page)}
          hideOnSinglePage
          style={{ textAlign: 'center', marginTop: 16 }}
        />
      </Modal>
    </Container>
  )
}

export default FileListItem
