import { css } from 'styled-components'

const breakpoints = {
  xxl: 1600,
  xl: 1240,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 480,
}

const mediaService = (sizes) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `

    return acc
  }, {})

export default mediaService(breakpoints)
