import React from 'react'
import { Avatar } from 'antd'
import { Item } from './styled'
import { images } from '../../../assets'
import { date } from '../../../helpers'
import { formatMoney } from '../../../helpers/money'
import { minutesToReadable } from '../../../helpers/time'

const EventItem = ({ item }) => {
  return (
    <Item>
      <Item.Inner>
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <Item.Title level={4} ellipsis={{ rows: 4, expandable: true, symbol: ' ' }}>
            {item.title}
          </Item.Title>

          {item.speakers.length > 0 && (
            <>
              <Item.SpeakersTitle>Speakers</Item.SpeakersTitle>
              <Item.Speakers>
                {item.speakers.map((speaker) => (
                  <Item.Speakers.Item key={speaker.id}>
                    <Avatar size={28} key={speaker.image} src={speaker.image} />
                    <Item.Speakers.Name>{speaker.name}</Item.Speakers.Name>
                  </Item.Speakers.Item>
                ))}
              </Item.Speakers>
            </>
          )}

          <Item.Info>
            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconDate} />
                <Item.Details.Label>Date</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>{date.formatUTC(item.start_date)}</Item.Details.Value>
            </Item.Details>

            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconRegion} />
                <Item.Details.Label>Location</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>
                <Item.Details.Truncate>{item.location}</Item.Details.Truncate>
              </Item.Details.Value>
            </Item.Details>

            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconMapPin} />
                <Item.Details.Label>Venue</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>
                <Item.Details.Truncate>{item.venue}</Item.Details.Truncate>
              </Item.Details.Value>
            </Item.Details>

            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconCoinPound} />
                <Item.Details.Label>Price</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>
                <Item.Details.Truncate>{formatMoney(item.price)} + VAT</Item.Details.Truncate>
              </Item.Details.Value>
            </Item.Details>

            <Item.Details>
              <Item.Details.Name>
                <Item.Details.Icon src={images.iconClock} />
                <Item.Details.Label>CPD Time</Item.Details.Label>
              </Item.Details.Name>

              <Item.Details.Value>
                <Item.Details.Truncate>{minutesToReadable(item.duration)}</Item.Details.Truncate>
              </Item.Details.Value>
            </Item.Details>
          </Item.Info>
        </div>
        <Item.Button type='primary' href={item.external_url} target='_blank' block>
          Book now
        </Item.Button>
      </Item.Inner>
    </Item>
  )
}

export default EventItem
