import styled from 'styled-components'
import { media } from '../../../../../styles'

const Watching = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  padding: 10rem 0;
  position: relative;

  ${media.lg`
    padding: 3.2rem 0;
  `}
`

Watching.SemiRing = styled.div`
  position: absolute;
  top: 15.6rem;
  left: -15rem;

  ${media.xl`
    display: none;
  `}
`

Watching.SemiCircle = styled.div`
  position: absolute;
  top: 7.5rem;
  right: -3.2rem;

  ${media.xl`
    display: none;
  `}
`

Watching.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  h2.ant-typography {
    ${media.lg`
      font-size: 3.2rem;
      margin: 0;
    `}
  }

  ${media.lg`
    margin-bottom: 1.2rem;
  `}
`

Watching.List = styled.div`
  margin: 0 -1.6rem;
  padding: 3.2rem 0;

  .slick-slider {
    .slick-dots {
      margin-top: 5.8rem;

      ${media.lg`
        margin-top: 3.2rem;
      `}
    }
  }

  ${media.lg`
    padding: 1.6rem 0;
    margin: 0;
  `}
`

Watching.Item = styled.div`
  padding: 0 1.6rem;

  ${media.lg`
    padding: 0;
  `}
`

export { Watching }
