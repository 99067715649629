const theme = {
  fontFamily: {
    primary: '"Sora", sans-serif',
    secondary: '"IBM Plex Sans", sans-serif',
  },
  colors: {
    border: {
      black: '#2F4E78',
    },
    gray: {
      100: '#FFFFFF',
      200: '#FAFAFA',
      300: '#F1F1F1',
      400: '#EAEAEA',
      500: '#BABABA',
      600: '#1A1A1A',
      700: '#000000',
    },
    blue: {
      100: '#1F3A5F',
      200: '#132B4B',
      300: '#0C203A',
    },
    yellow: {
      100: '#F3DA81',
      200: '#E4CB72',
      300: '#D9BF63',
    },
    violet: {
      100: '#9D6CA5',
      200: '#8D5596',
      300: '#704178',
    },
    red: {
      100: '#F99388',
      200: '#EE8175',
      300: '#E17367',
    },
    lightBlue: {
      100: '#8BC1E8',
      200: '#76AED6',
      300: '#66A0C9',
    },
    green: {
      100: '#8DDEDB',
      200: '#7CCDCA',
      300: '#6BBCB9',
    },
    grayBlue: {
      100: '#E3E6EB',
      200: '#DDDEE3',
      300: '#9A9CAB',
    },
  },
}

export default theme
