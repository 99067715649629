import styled from 'styled-components'
import { media } from '../../../../../styles'
import { Webinar } from '../../styled'

const PreviousWebinars = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  padding: 10rem 0;
  position: relative;

  ${media.lg`
    padding: 3.2rem 0;
  `}
`

PreviousWebinars.SemiRing = styled.div`
  position: absolute;
  left: -10rem;
  top: 35rem;

  ${media.lg`
    display: none;
  `}
`

Webinar.CircleYellow = styled.div`
  position: absolute;
  display: none;

  ${media.xs`
    top: 2.4rem;
    right: -7.5rem;
    display: block;
  `}
`

PreviousWebinars.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6.4rem;

  h2.ant-typography {
    ${media.lg`
      font-size: 3.2rem;
    `}
  }

  ${media.lg`
    margin-bottom: 1.6rem;
  `}
`

PreviousWebinars.More = styled.div`
  margin-top: 2.4rem;
`

export { PreviousWebinars }
