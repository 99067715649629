import { rgba } from 'polished'
import styled from 'styled-components'
import { images } from '../../../assets'
import { media } from '../../../styles'

const Feedback = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  padding: 10rem 0;
  position: relative;

  ${media.xl`
    padding: 3.2rem 0;
  `}
`

Feedback.Card = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  border-radius: 0.8rem;
  padding: 4rem;
  position: relative;

  ${media.lg`
    padding: 2.8rem;
  `}
`

Feedback.Card.Title = styled.div`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3.2rem;
`

Feedback.Card.Pacman = styled.div`
  position: absolute;
  right: 3.2rem;
  top: -3rem;
  z-index: 1;

  ${media.lg`
    right: 2.4rem;
  `}
`

Feedback.Card.SemiCircle = styled.div`
  position: absolute;
  left: calc(50% + 2rem);
  bottom: -2rem;

  ${media.xl`
    display: none;
  `}
`

Feedback.Card.SemiRing = styled.div`
  position: absolute;
  left: -6.4rem;
  top: calc(50% - 6.4rem);
  z-index: 1;
`

Feedback.Submit = styled.div`
  text-align: right;
`

Feedback.Header = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4.4rem;

  h3.ant-typography {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    margin: 0;

    span {
      color: ${({ theme }) => theme.colors.lightBlue[200]};
      font-weight: 700;
    }

    ${media.xl`
      font-size: 2.4rem;
    `}
  }

  ${media.xl`
    margin-bottom: 3.2rem;
  `}
`

Feedback.Icon = styled.div`
  min-width: 8.8rem;
  width: 8.8rem;
  height: 8.8rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Feedback.Details = styled.div`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 4rem;

  ${media.xl`
    margin-bottom: 3.2rem;
  `}
`

Feedback.Text = styled.div`
  font-size: 1.6rem;
  margin-bottom: 4.8rem;

  ${media.xl`
    margin-bottom: 3.2rem;
  `}
`

Feedback.Refer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  border-radius: 0.8rem;
  padding: 3.2rem;
  margin-top: 3.2rem;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 19.2rem;
    height: 19.2rem;
    background-color: ${({ theme }) => theme.colors.green[200]};
    border-radius: 50%;
    position: absolute;
    left: -9rem;
    bottom: -12rem;
  }

  &:before {
    content: '';
    width: 7.2rem;
    height: 7.2rem;
    background: url(${images.iconKey}) no-repeat center / contain;
    position: absolute;
    left: 2.4rem;
    bottom: 1.2rem;
    z-index: 1;
  }
`

Feedback.Refer.Inner = styled.div`
  position: relative;
  z-index: 2;
`

Feedback.Refer.Title = styled.div`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2.8rem;
`

Feedback.Refer.Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;

  .ant-btn {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
    font-weight: 600;
  }
`

export { Feedback }
