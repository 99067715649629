import queryString from 'query-string'
import { api } from '../../services'
import { categories as consts } from '../constants'

const getCategoriesList = (query) => async (dispatch) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_CATEGORIES_LIST,
      payload: true,
    })

    const { data } = await api.get(`/categories?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_CATEGORIES_LIST,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_CATEGORIES_LIST,
      payload: false,
    })
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getCategoriesList }
