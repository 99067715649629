import { rgba } from 'polished'
import styled from 'styled-components'
import { media } from '../../../../../styles'

const Overview = styled.div`
  h3.ant-typography {
    font-size: 2rem;
    margin-bottom: 2.8rem;

    ${media.lg`
      margin-bottom: 1.6rem;
    `}
  }
`

Overview.Card = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
  border-radius: 0.8rem;
  padding: 3.2rem;
  margin-bottom: 3.2rem;

  ${media.lg`
    padding: 2.4rem;
    margin-bottom: ${({ isLast }) => (isLast ? 0 : '2.8rem')} ;
  `}
`

Overview.Stats = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.8rem 0;
  margin-bottom: 2.8rem;

  h2.ant-typography {
    margin-bottom: 0;
    min-width: fit-content;
    ${media.lg`
      font-size: 3.2rem;
      line-height: 4.4rem;
    `}
  }

  .ant-typography-secondary {
    font-size: 1rem;
    text-transform: uppercase;
  }

  ${media.lg`
    flex-direction: column;
    padding: 0.8rem 1.6rem;
  `}
`

Overview.Stat = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 0 4rem;

  &:not(:last-child) {
    border-right: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};

    ${media.lg`
      border-right: 0;
      border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
    `}
  }

  &:last-child {
    ${media.lg`
      padding-top: 0.8rem;
    `}
  }

  ${media.lg`
    width: 100%;
    padding: 0;
  `}
`

Overview.Stat.Number = styled.span`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 5.6rem;

  ${media.lg`
    font-size: 3.2rem;
    line-height: 4.4rem;
  `}
`

Overview.Progress = styled.div`
  margin-top: 1.8rem;
`

Overview.Progress.Bar = styled.div`
  height: 0.8rem;
  background-color: ${({ theme }) => theme.colors.gray[400]};
  border-radius: 0.8rem;
  position: relative;
`

Overview.Progress.Process = styled.div`
  height: 0.8rem;
  background-color: ${({ theme }) => theme.colors.green[200]};
  border-radius: 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
`

Overview.Progress.Current = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  background-color: ${({ theme }) => theme.colors.green[200]};
  border-radius: 50%;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[100]};
  box-shadow: 0 0.4rem 0.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.12)};
  color: ${({ theme }) => theme.colors.gray[100]};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1.6rem;
  right: -1.8rem;
`

Overview.Progress.Counts = styled.div`
  color: ${({ theme }) => theme.colors.gray[500]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-top: 1.1rem;

  span {
    width: calc(100% / 20);
    display: block;
    text-align: center;
  }
`

Overview.List = styled.div`
  margin: 0 -1.6rem;

  .slick-slider {
    .slick-dots {
      ${media.lg`
        margin-top: 3.2rem;
      `}
    }
  }

  ${media.lg`
    padding: 1.6rem 0;
    margin: 0;
  `}
`

Overview.Item = styled.div`
  padding: 0 1.6rem;

  ${media.lg`
    padding: 0;
    padding: 0 0.5rem;
  `}
`

export { Overview }
