import React, { useCallback } from 'react'
import FeedbackPage from './FeedbackPage'
import { useActions } from '../../../hooks'
import { feedback as feedbackActions } from '../../../store/actions'

const FeedbackPageContainer = () => {
  const [feedbackDispatch] = useActions([feedbackActions])
  const sendFeedback = useCallback(feedbackDispatch.sendFeedback, [feedbackDispatch])

  const props = {
    sendFeedback,
  }

  return <FeedbackPage {...props} />
}

export default FeedbackPageContainer
