import { panels as consts } from '../constants'

const initialState = {
  isFetchingSettings: false,
  settings: {},
}

// eslint-disable-next-line default-param-last
const panelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCHING_PANELS_SETTINGS: {
      return {
        ...state,
        isFetchingSettings: action.payload,
      }
    }

    case consts.RECEIVE_PANELS_SETTINGS: {
      return {
        ...state,
        isFetchingSettings: false,
        settings: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default panelsReducer
