import { Layout as LayoutLibrary } from 'antd'
import styled from 'styled-components'

const Layout = styled(LayoutLibrary)`
  min-height: 100vh;
`

const Content = styled(LayoutLibrary.Content)`
  overflow-x: hidden;
`

export { Layout, Content }
