import styled from 'styled-components'

const ModalTitle = styled.span`
  margin-right: 7rem;
  font-weight: 600;
`

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[400]};
  height: 1px;
  margin: 2.4rem 0;
`

const Training = styled.div`
  .ant-form-item-with-help .ant-form-item-explain {
    margin-top: 5px;
    margin-bottom: 0;
  }
`

Training.Submit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export { Training, ModalTitle, Separator }
