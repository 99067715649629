import React, { useCallback } from 'react'
import AccountProfile from './AccountProfile'
import { useActions } from '../../../../../hooks'
import { auth as authActions } from '../../../../../store/actions'

const AccountProfileContainer = ({ ...rest }) => {
  const [authDispatch] = useActions([authActions])
  const updateProfileDetails = useCallback(authDispatch.updateProfileDetails, [authDispatch])
  const updateProfilePassword = useCallback(authDispatch.updateProfilePassword, [authDispatch])

  const props = {
    updateProfileDetails,
    updateProfilePassword,
    ...rest,
  }

  return <AccountProfile {...props} />
}

export default AccountProfileContainer
