import React, { useCallback } from 'react'
import ModalAddTraining from './ModalAddTraining'
import { useActions } from '../../../../../hooks'
import { history as historyActions } from '../../../../../store/actions'

const ModalAddTrainingContainer = ({ ...rest }) => {
  const [historyDispatch] = useActions([historyActions])
  const createCPDLog = useCallback(historyDispatch.createCPDLog, [historyDispatch])

  const props = {
    createCPDLog,
    ...rest,
  }

  return <ModalAddTraining {...props} />
}

export default ModalAddTrainingContainer
