import { combineReducers } from 'redux'
import auth from './auth'
import blogPosts from './blog-posts'
import categories from './categories'
import counter from './counter'
import events from './events'
import feedback from './feedback'
import history from './history'
import panels from './panels'
import regions from './regions'
import speakers from './speakers'
import statistics from './statistics'
import webinars from './webinars'

export default combineReducers({
  auth,
  webinars,
  events,
  categories,
  regions,
  speakers,
  counter,
  history,
  feedback,
  statistics,
  panels,
  blogPosts,
})
