import styled from 'styled-components'
import { media } from '../../../styles'

const List = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  grid-gap: 3.2rem;
  position: relative;
  z-index: 1;

  ${media.lg`
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    grid-gap: 1.2rem;
  `}
`

const Icon = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  background: url(${({ src }) => src}) no-repeat center / contain;

  ${media.lg`
    width: 4rem;
    height: 4rem;
  `}
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-size: 1.4rem;
  font-weight: 600;
`

const Item = styled.a`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
  border-radius: 0.8rem;
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.1s ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.colors.red[200]};

    ${Name} {
      color: ${({ theme }) => theme.colors.gray[100]};
    }

    ${Icon} {
      filter: brightness(0) invert(1);
    }

    .anticon {
      color: ${({ theme }) => theme.colors.gray[100]};
    }
  }

  .anticon {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-size: 1.6rem;
  }

  ${media.lg`
    padding: 1.2rem 1.6rem;
  `}
`

Item.Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 2.8rem;

  ${media.lg`
    gap: 2rem;
  `}
`

export { List, Icon, Name, Item }
