import queryString from 'query-string'
import { api } from '../../services'
import { blogPosts as consts } from '../constants'

const getBlogPosts = (query) => async (dispatch) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_BLOG_POSTS,
      payload: true,
    })

    const { data } = await api.get(`/blog-posts?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_BLOG_POSTS,
      payload: {
        list: data.data,
        meta: data.meta,
      },
    })
  } catch (error) {
    dispatch({
      type: consts.FETCHING_BLOG_POSTS,
      payload: false,
    })

    console.log(error)
  }
}

const getBlogPostsCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_BLOG_POSTS_CATEGORIES,
      payload: true,
    })

    const { data } = await api.get(`/blog-posts/categories`)

    dispatch({
      type: consts.RECEIVE_BLOG_POSTS_CATEGORIES,
      payload: {
        list: data.data,
        meta: data.meta,
      },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_BLOG_POSTS_CATEGORIES,
      payload: false,
    })
  }
}

const getBlogPostDetails = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_BLOG_POST_DETAILS,
      payload: true,
    })

    const { data } = await api.get(`/blog-posts/${slug}`)

    dispatch({
      type: consts.RECEIVE_BLOG_POST_DETAILS,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_BLOG_POST_DETAILS,
      payload: false,
    })
  }
}

export { getBlogPosts, getBlogPostsCategories, getBlogPostDetails }
