import { regions as consts } from '../constants'

const initialState = {
  isFetchingRegionsList: false,
  list: [],
}

// eslint-disable-next-line default-param-last
const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCHING_REGIONS_LIST: {
      return {
        ...state,
        isFetchingRegionsList: action.payload,
      }
    }

    case consts.RECEIVE_REGIONS_LIST: {
      return {
        ...state,
        isFetchingRegionsList: false,
        list: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default regionsReducer
