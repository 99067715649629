import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'

const initialFormat = 'dd/MM/yyyy HH:mm'

// eslint-disable-next-line import/prefer-default-export
export const formatUTC = (dateString, ft = initialFormat) => {
  const parsedTime = parseISO(dateString)

  return format(parsedTime, ft)
}
