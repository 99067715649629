import React, { useEffect } from 'react'
import { Typography, Grid, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { UpcomingWebinars } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import {
  LayoutCentering,
  Ellipse,
  CustomCarousel,
  WebinarsUpcomingList,
  WebinarUpcomingItem,
} from '../../../../components'

const { Title } = Typography
const { useBreakpoint } = Grid

const MainFeaturedWebinars = ({ getWebinarsUpcoming }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const { isFetchingUpcomingWebinars, upcomingWebinars } = useShallowEqualSelector((state) => state.webinars)

  useEffect(() => {
    getWebinarsUpcoming({ per_page: 6 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (upcomingWebinars.list.length === 0) {
    return null
  }

  return (
    <UpcomingWebinars>
      <UpcomingWebinars.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </UpcomingWebinars.CircleGreen>

      <UpcomingWebinars.CircleYellow>
        <Ellipse type='circle' color={theme.colors.yellow[200]} size={10} />
      </UpcomingWebinars.CircleYellow>

      <UpcomingWebinars.SemiRing>
        <Ellipse type='semiring' color={theme.colors.red[200]} size={18} rotate={-45} />
      </UpcomingWebinars.SemiRing>

      <UpcomingWebinars.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </UpcomingWebinars.SemiCircle>

      <LayoutCentering>
        <UpcomingWebinars.Header>
          <Title level={2}>Upcoming Webinars</Title>

          {screens.lg && (
            <Button type='primary' onClick={() => navigate('/upcoming-webinars')}>
              View all
            </Button>
          )}
        </UpcomingWebinars.Header>

        {screens.lg ? (
          <WebinarsUpcomingList loading={isFetchingUpcomingWebinars} dataSource={upcomingWebinars.list} />
        ) : (
          <UpcomingWebinars.List>
            <CustomCarousel
              slidesToShow={screens.lg ? 1.05 : 1}
              variableWidth={screens.lg}
              centerMode={screens.lg}
              infinite
              hideArrows={(screens.lg && upcomingWebinars.list.length <= 3) || upcomingWebinars.list.length <= 1}
            >
              {upcomingWebinars.list.map((item) => (
                <UpcomingWebinars.Item key={item.id}>
                  <WebinarUpcomingItem item={item} />
                </UpcomingWebinars.Item>
              ))}
            </CustomCarousel>

            <UpcomingWebinars.More>
              <Button type='primary' onClick={() => navigate('/upcoming-webinars')} block>
                View all
              </Button>
            </UpcomingWebinars.More>
          </UpcomingWebinars.List>
        )}
      </LayoutCentering>
    </UpcomingWebinars>
  )
}

export default MainFeaturedWebinars
