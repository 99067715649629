export const FETCHING_WEBINARS_UPCOMING = 'FETCHING_WEBINARS_UPCOMING'
export const RECEIVE_WEBINARS_UPCOMING = 'RECEIVE_WEBINARS_UPCOMING'

export const FETCHING_WEBINARS_RECORDED = 'FETCHING_WEBINARS_RECORDED'
export const RECEIVE_WEBINARS_RECORDED = 'RECEIVE_WEBINARS_RECORDED'

export const FETCHING_WEBINARS_INPROGRESS = 'FETCHING_WEBINARS_INPROGRESS'
export const RECEIVE_WEBINARS_INPROGRESS = 'RECEIVE_WEBINARS_INPROGRESS'

export const FETCHING_WEBINARS_RECORDED_OF_SPEACKERS = 'FETCHING_WEBINARS_RECORDED_OF_SPEACKERS'
export const RECEIVE_WEBINARS_RECORDED_OF_SPEACKERS = 'RECEIVE_WEBINARS_RECORDED_OF_SPEACKERS'

export const FETCHING_WEBINAR_DETAILS = 'FETCHING_WEBINAR_DETAILS'
export const RECEIVE_WEBINAR_DETAILS = 'RECEIVE_WEBINAR_DETAILS'

export const FETCHING_REGISTER_TO_WEBINAR = 'FETCHING_REGISTER_TO_WEBINAR'
export const RECEIVE_REGISTER_TO_WEBINAR = 'RECEIVE_REGISTER_TO_WEBINAR'

export const FETCHING_REGISTER_TO_ALL_WEBINARS = 'FETCHING_REGISTER_TO_ALL_WEBINARS'
export const RECEIVE_REGISTER_TO_ALL_WEBINARS = 'RECEIVE_REGISTER_TO_ALL_WEBINARS'

export const FETCHING_CANCEL_WEBINAR_REGISTRATION = 'FETCHING_CANCEL_WEBINAR_REGISTRATION'
export const RECEIVE_CANCEL_WEBINAR_REGISTRATION = 'RECEIVE_CANCEL_WEBINAR_REGISTRATION'
