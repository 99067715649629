import React, { useCallback } from 'react'
import WebinarsContinueWatching from './WebinarsContinueWatching'
import { useActions } from '../../../../../hooks'
import { webinars as webinarsActions } from '../../../../../store/actions'

const WebinarsContinueWatchingContainer = () => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsInProgress = useCallback(webinarsDispatch.getWebinarsInProgress, [webinarsDispatch])

  const props = {
    getWebinarsInProgress,
  }

  return <WebinarsContinueWatching {...props} />
}

export default WebinarsContinueWatchingContainer
