import { Layout as LayoutLibrary } from 'antd'
import styled, { css } from 'styled-components'
import { images } from '../../../assets'
import { media } from '../../../styles'

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 998;
`

const Layout = styled(LayoutLibrary.Header)`
  width: 100%;
  line-height: 1;
  background-color: ${({ theme }) => theme.colors.blue[200]};
  display: flex;
  align-items: center;

  ${media.lg`
    height: 8.8rem;
    padding: 0 1.6rem;
  `}

  ${({ pattern }) =>
    pattern &&
    css`
      background: ${({ theme }) => theme.colors.blue[200]} url(${images.patternKeyCircle}) repeat-x bottom / 64rem;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          ${({ theme }) => theme.colors.blue[200]} 24%,
          rgba(19, 43, 75, 0.4) 170%
        );
        position: absolute;
        bottom: 0;
        left: 0;
      }
    `}
`

Layout.Inner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

Layout.SemiCircle = styled.div`
  position: absolute;
  left: calc(50% + 10rem);
  top: -4rem;
  z-index: 2;

  ${media.md`
    display: none;
  `}
`

Layout.Menu = styled.div``

Layout.Menu.Inner = styled.div`
  display: block;

  ${media.xxl`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
  `}
`

Layout.Menu.Toggle = styled.a`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.red[200]};
  color: ${({ theme }) => theme.colors.gray[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`

Layout.Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  .ant-typography {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`

const Logotype = styled.img`
  width: 23.6rem;
  position: relative;
  z-index: 1;

  ${media.lg`
    width: 21.6rem;
  `}
`

export { Wrapper, Layout, Logotype }
