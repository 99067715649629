import { rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'
import mediaService from './media'
// eslint-disable-next-line import/no-unresolved
import 'react-pdf/dist/Page/AnnotationLayer.css'
// eslint-disable-next-line import/no-unresolved
import 'react-pdf/dist/Page/TextLayer.css'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%; // 1rem = 10px
  }

  h2, h4 {
    font-weight: 700;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .ant-list {
    &-empty-text {
      display: none;
    }

    .ant-spin-nested-loading {
      .ant-spin-container {
        &:after {
          background-color: ${({ theme }) => theme.colors.gray[300]};
        }
      }
    }
  }

  .ant-card {
    &-bordered {
      border-color: ${({ theme }) => theme.colors.grayBlue[200]};

      .ant-card-body {
        ${mediaService.lg`
          padding: 2.4rem;
        `}
      }
    }
  }

  .ant-avatar-group {
    .ant-avatar {
      border-width: 0.2rem;
      border: none;

      &:not(:first-child) {
        margin-left: -3rem;
      }
    }
  }

  .ant-form {
    &-item {
      &-label {
        font-family: ${({ theme }) => theme.fontFamily.secondary};
        font-weight: 500;
        padding-bottom: 0.8rem !important;

        & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
          &:before {
            content: '*';
            font-family: ${({ theme }) => theme.fontFamily.secondary};
            position: absolute;
            top: .2rem;
            right: -1rem;
            margin: 0;
            color: currentColor;
          }
        }
      }

      &-extra {
        font: 500 1.2rem ${({ theme }) => theme.fontFamily.secondary};
        position: absolute;
        top: -2.4rem;
        right: 0;

        &-custom {
          font-family: ${({ theme }) => theme.fontFamily.secondary};
          color: ${({ theme }) => theme.colors.grayBlue[300]};
          font-size: 1.2rem;
          line-height: 1.6rem;
          font-weight: 400;
          margin-top: -1.5rem;
          position: relative;
          margin-bottom: 2.4rem;
        }
      }

      &-explain {
        font-size: 1.2rem;
        margin-top: .8rem;
        font-family: ${({ theme }) => theme.fontFamily.secondary};
        margin-bottom: 2rem;

        &-error {
          //margin-bottom: 2rem;
        }

        .ant-typography {
          color: ${({ theme }) => theme.colors.grayBlue[300]};
          display: block;
          margin-bottom: 1.6rem;
          text-align: left;
        }
      }
    }
  }

  .ant-input,
  .ant-input-affix-wrapper {
    &:focus,
    &.ant-input-affix-wrapper-focused,
    &.ant-input-focused {
      border-color: ${({ theme }) => theme.colors.blue[200]};
      box-shadow: none;

      &:hover {
        border-color: ${({ theme }) => theme.colors.blue[200]};
      }
    }

    &-suffix {
      .anticon {
        color: ${({ theme }) => theme.colors.gray[600]};
      }
    }
  }

  .ant-checkbox {
    &-inner {
      border-color: ${({ theme }) => theme.colors.gray[600]};
    }
  }

  .ant-checkbox-group {
    &-item {
      margin-bottom: 0.8rem;
    }
  }

  .ant-btn {
    padding: 1.2rem 2.4rem;

    &:is(a) {
      padding: 1.2rem 2.4rem !important;
      line-height: 1;
    }

    &.ant-btn-link {
      &:hover {
        background-color: ${rgba('#EE8175', 0.05)};
        color: ${({ theme }) => theme.colors.red[200]};
        border-color: transparent;
      }

      &:active,
      &:focus {
        background-color: ${rgba('#EE8175', 0.1)};
        color: ${({ theme }) => theme.colors.red[200]};
        border-color: transparent;
      }
    }

    &.ant-btn-link-dark {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.gray[100]};

      &:hover {
        background-color: ${rgba('#FFFFFF', 0.04)};
        border-color: transparent;
      }

      &:active,
      &:focus {
        background-color: ${rgba('#FFFFFF', 0.08)};
        border-color: transparent;
      }
    }

    &.ant-btn-background-ghost {
      &:hover {
        background-color: ${rgba('#EE8175', 0.05)};
        border-color: ${({ theme }) => theme.colors.red[200]};
        color: ${({ theme }) => theme.colors.red[200]};
      }

      &:active,
      &:focus {
        background-color: ${rgba('#EE8175', 0.1)};
        border-color: ${({ theme }) => theme.colors.red[200]};
        color: ${({ theme }) => theme.colors.red[300]};
      }
    }

    &.ant-btn-ghost-dark {
      border-color: ${({ theme }) => theme.colors.red[200]};
      color: ${({ theme }) => theme.colors.gray[100]};

      &:hover {
        background-color: ${rgba('#EE8175', 0.05)};
        border-color: ${({ theme }) => theme.colors.red[200]};
        color: ${({ theme }) => theme.colors.gray[100]};
      }

      &:active,
      &:focus {
        background-color: ${rgba('#EE8175', 0.1)};
        border-color: ${({ theme }) => theme.colors.red[200]};
        color: ${({ theme }) => theme.colors.gray[100]};
      }
    }

    &.ant-dropdown-trigger-custom {
      box-shadow: none;
      background-color: ${({ theme }) => theme.colors.red[200]};
      color: ${({ theme }) => theme.colors.gray[100]};

      ${mediaService.xs`
        width: 100%;
      `}
      &[disabled] {
        background-color: ${({ theme }) => theme.colors.gray[400]};
        color: ${({ theme }) => theme.colors.gray[500]};
      }

      &-item {
        font-weight: 600;
        padding: 1.6rem;

        &:not(:last-child) {
          border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
        }
      }
    }

    &.ant-btn-primary {
      &[disabled] {
        background: ${({ theme }) => theme.colors.gray[400]};
        color: ${({ theme }) => theme.colors.gray[500]};
      }
    }
  }

  .ant-carousel {
    .slick-slider {
      .slick-slide {
        pointer-events: all;
      }

      .slick-dots {
        margin-top: 3.2rem;
        margin-bottom: 0;
        position: relative;
        bottom: initial;

        li {
          min-width: 2rem;
          width: 2rem;
          height: 2rem;
          margin: 0 .8rem;

          button {
            height: 2rem;
            border-radius: 50%;
            border: .1rem solid ${({ theme }) => theme.colors.gray[400]};
            opacity: 1;
          }

          &.slick-active {
            button {
              background: ${({ theme }) => theme.colors.red[200]};
              border-color: ${({ theme }) => theme.colors.gray[400]};
            }
          }
        }
      }
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: transparent;
    }

    &-checked {
      .ant-checkbox-inner {
        background-color: transparent;

        &:after {
          border-color: ${({ theme }) => theme.colors.red[200]};
        }
      }
    }

    & + span {
      padding-left: 1.2rem;
    }
  }

  .dropdown-bordered-menu {
    .ant-dropdown-menu {
      border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
      box-shadow: none;

      &-item {
        font-weight: 600;
        padding: 1.6rem;

        &:not(:last-child) {
          border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
        }
      }
    }
  }

  .ant-modal-wrap {
    &.ant-full-screen-on-mobile {
      ${mediaService.md`
      overflow-x: hidden;
      overflow-y: auto;

      .ant-modal {
        max-width: calc(100vw - 32px);
      }

      .textLayer,
      .annotationLayer {
        display: none;
      }
    `}
  }

  .ant-modal {
    .ant-modal-content {
      & > .ant-modal-close {
        top: 1.6rem;
        right: 1.6rem;

        .ant-modal-close-x {
          width: 16px;
          height: 16px;
          line-height: 1;
        }
      }
    }

    &.modal-filter-wide {
      max-width: 100%;
      padding-bottom: 0;
      top: 0;
      margin: 0;

      .ant-modal-content {
        min-height: 100vh;
        border-radius: 0;
      }

      .ant-modal-close {
        top: 3.2rem;
        right: 3.2rem;

        &-x {
          width: 4.8rem;
          height: 4.8rem;
          line-height: 4.8rem;
          border: 0.1rem solid ${({ theme }) => theme.colors.red[200]};
          border-radius: 0.4rem;
          font-size: 1.8rem;
        }

        ${mediaService.lg`
          top: 1.6rem;
          right: 1.6rem;
        `}
      }

      .ant-modal-body {
        padding: 3.2rem;

        ${mediaService.lg`
          padding: 1.6rem;
        `}
      }

      .ant-collapse {
        &.ant-collapse-ghost {
          .ant-collapse-item {
            &:not(:last-child) {
              border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
            }

            &-active {
              .ant-collapse-header {
                .ant-collapse-arrow {
                  transform: rotate(-180deg);
                }
              }
            }
          }

          .ant-collapse-header {
            color: ${({ theme }) => theme.colors.gray[600]};
            font-weight: 600;
            padding: 2rem 0;

            .ant-collapse-arrow {
              transform: rotate(90deg);
              top: calc(50% - 0.5rem);
              right: 0;
            }
          }

          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }

      .ant-checkbox-group {
        width: 100%;

        &-item {
          width: 100%;
          margin-bottom: 1.2rem;

          .ant-checkbox {
            & + span {
              width: 100%;
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .ant-table-wrapper {
    .ant-table {
      &-thead {
        .ant-table-cell {
          font-size: 1rem;
          text-transform: uppercase;

          &:before {
            display: none;
          }
        }
      }

      &-tbody {
        .ant-table-cell {
          vertical-align: top;
        }
      }
    }

    &.table-cpd-log {
      .ant-table {
        &-tbody {
          font-family: ${({ theme }) => theme.fontFamily.secondary};

          
          .ant-table-row:nth-child(odd){
            background-color: ${({ theme }) => theme.colors.gray[200]};
          }
          
          .ant-table-cell-cpd-log {
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
          }

          .ant-table-cell-cpd-log-icon {
            padding: 1.6rem 0;
            margin: 0;
          }

          .ant-table-cell-cpd-log-duration {
            padding: 1.6rem 0.8rem;
          }
        }
      }
    }
  }

  .ant-tooltip-content {


    .ant-tooltip-inner {
      background: rgba(26, 26, 26, 0.9);
      opacity: 0.9;
      font-family: ${({ theme }) => theme.fontFamily.primary};
      font-weight: 400;
      font-size: 1.2rem;
      padding: 1.2rem;
    }
  }
`

export default GlobalStyle
