import React from 'react'
import { Grid, Typography, Button } from 'antd'
import Lottie from 'react-lottie'
import { useNavigate } from 'react-router-dom'
import { NotFound } from './styled'
import { animations } from '../../../assets'
import { useDocumentTitle } from '../../../hooks'
import { theme } from '../../../styles'
import { Ellipse } from '../../components'

const { useBreakpoint } = Grid
const { Title, Text } = Typography

const NotFoundPage = () => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  useDocumentTitle('Page not found')

  return (
    <NotFound>
      <NotFound.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={screens.lg ? 8 : 5.6} rotate={-45} />
      </NotFound.SemiCircle>

      <NotFound.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={screens.lg ? 25 : 6} rotate={-45} />
      </NotFound.SemiRing>

      <Lottie
        height={195}
        options={{
          loop: true,
          autoplay: true,
          animationData: animations.error404,
        }}
      />

      <Title level={2}>Page not found</Title>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <Text>We can't seem to find the page you're looking for.</Text>

      <Button type='primary' onClick={() => navigate('/')}>
        Back to homepage
      </Button>
    </NotFound>
  )
}

export default NotFoundPage
