import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'

const Profile = styled.div`
  h3.ant-typography {
    font-size: 2rem;
    margin-bottom: 2.8rem;
  }
`

Profile.Card = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
  border-radius: 0.8rem;
  padding: 3.2rem;

  ${media.lg`
    padding: 2.4rem;
  `}
`

Profile.Submit = styled.div`
  text-align: right;
`

Profile.Feedback = styled.div`
  background-color: ${({ theme }) => theme.colors.blue[200]};
  border-radius: 0.8rem;
  color: ${({ theme }) => theme.colors.gray[100]};
  padding: 3.2rem;
  margin-top: 3.2rem;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 19.2rem;
    height: 19.2rem;
    background-color: ${({ theme }) => theme.colors.green[200]};
    border-radius: 50%;
    position: absolute;
    left: -9rem;
    bottom: -12rem;
  }

  &:before {
    content: '';
    width: 7.2rem;
    height: 7.2rem;
    background: url(${images.iconFeedbackLight}) no-repeat center / contain;
    position: absolute;
    left: 2.4rem;
    bottom: 1.2rem;
    z-index: 1;
  }

  .ant-typography {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`

Profile.Feedback.Inner = styled.div`
  position: relative;
  z-index: 2;
`

Profile.Feedback.Title = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2.8rem;
`

Profile.Feedback.Description = styled.span`
  color: ${({ theme }) => theme.colors.gray[100]};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
`

Profile.Feedback.Action = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;

  & > * {
    font-weight: 600;
  }
`

export { Profile }
