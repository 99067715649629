import React from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { Wrapper } from './styled'

const GoToMain = () => (
  <Wrapper>
    <Wrapper.Link href='https://www.estateresearch.co.uk/' target='_blank'>
      <Space>
        <LeftOutlined />
        Go to main website
      </Space>
    </Wrapper.Link>
  </Wrapper>
)

export default GoToMain
