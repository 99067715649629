import React, { useCallback } from 'react'
import FilterBy from './FilterBy'
import { useActions } from '../../../hooks'
import {
  regions as regionsActions,
  categories as categoriesActions,
  speakers as speakersActions,
  blogPosts as blogPostsActions,
  events as eventsActions,
} from '../../../store/actions'

const FilterByContainer = ({ ...rest }) => {
  const [regionsDispatch] = useActions([regionsActions])
  const getRegionsList = useCallback(regionsDispatch.getRegionsList, [regionsDispatch])

  const [categoriesDispatch] = useActions([categoriesActions])
  const getCategoriesList = useCallback(categoriesDispatch.getCategoriesList, [categoriesDispatch])

  const [speakersDispatch] = useActions([speakersActions])
  const getSpeakersListFull = useCallback(speakersDispatch.getSpeakersListFull, [speakersDispatch])

  const [blogPostsDispatch] = useActions([blogPostsActions])
  const getBlogPostsCategories = useCallback(blogPostsDispatch.getBlogPostsCategories, [blogPostsDispatch])

  const [eventsDispatch] = useActions([eventsActions])
  const getEventsLocations = useCallback(eventsDispatch.getEventsLocations, [eventsDispatch])

  const props = {
    getRegionsList,
    getCategoriesList,
    getSpeakersListFull,
    getBlogPostsCategories,
    getEventsLocations,
    ...rest,
  }

  return <FilterBy {...props} />
}

export default FilterByContainer
