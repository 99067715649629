import { rgba } from 'polished'
import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'

const FeaturedWebinars = styled.div`
  background: ${({ theme }) => theme.colors.blue[200]} url(${images.patternKeyCircle}) repeat-x bottom / 64rem;
  padding: 10rem 0 8rem 0;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, ${({ theme }) => theme.colors.blue[200]} 24%, rgba(19, 43, 75, 0.4) 170%);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${media.lg`
    padding: 3.2rem 0;
  `}
`

FeaturedWebinars.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6.4rem;
  position: relative;
  z-index: 1;

  h2.ant-typography {
    color: ${({ theme }) => theme.colors.gray[200]};

    ${media.lg`
      font-size: 3.2rem;
    `}
  }

  ${media.lg`
    margin-bottom: 1.6rem;
  `}
`

FeaturedWebinars.List = styled.div`
  position: relative;
  z-index: 1;

  .slick-slider {
    .slick-list {
      margin: 0 -1.6rem;
      padding: 0 16.5rem !important;

      ${media.lg`
        margin: 0;
        padding: 0 !important;
      `}
    }

    .slick-slide {
      margin: 0 1.6rem;

      ${media.lg`
        margin: 0;
      `}
    }

    .slick-dots {
      margin-top: 6.6rem;

      li {
        button {
          background-color: ${({ theme }) => rgba(theme.colors.gray[100], 0.1)};
          border: 0.1rem solid ${({ theme }) => theme.colors.gray[100]};
        }

        &.slick-active {
          button {
            border-color: ${({ theme }) => theme.colors.red[200]};
          }
        }
      }

      ${media.lg`
        margin-top: 3.2rem;
      `}
    }
  }

  ${media.lg`
    padding: 0 1.6rem;
    margin-right: -1.6rem;
  `}
`

FeaturedWebinars.Item = styled.div`
  width: 62.4rem !important;

  h4.ant-typography {
    min-height: 11.2rem;
    ${media.lg`
      min-height: max-content;
    `}
  }

  ${media.lg`
    width: 100% !important;
    padding-right: 1.6rem;
  `}
`

FeaturedWebinars.More = styled.div`
  padding: 0 1.6rem;
  margin-top: 2.4rem;
`

export { FeaturedWebinars }
