import React, { useCallback } from 'react'
import WebinarsUpcomingWideList from './WebinarsUpcomingWideList'
import { useActions } from '../../../hooks'
import { webinars as webinarsActions } from '../../../store/actions'

const WebinarsUpcomingWideListContainer = ({ ...rest }) => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const registerToWebinar = useCallback(webinarsDispatch.registerToWebinar, [webinarsDispatch])
  const cancelWebinarRegistration = useCallback(webinarsDispatch.cancelWebinarRegistration, [webinarsDispatch])

  const props = {
    registerToWebinar,
    cancelWebinarRegistration,
    ...rest,
  }

  return <WebinarsUpcomingWideList {...props} />
}

export default WebinarsUpcomingWideListContainer
