import React from 'react'
import { AuthLayout, MainLayout, LoggedLayout } from './views/layouts'
import {
  LoginPage,
  RegisterPage,
  RegisterEmailConfirmed,
  RegisterEmailFailed,
  ResetPasswordPage,
  ChangePasswordPage,
  MainPage,
  NotFoundPage,
  PrivacyPolicyPage,
  UpcomingWebinarsPage,
  PreviousWebinarsPage,
  EventsPage,
  WebinarDetailsPage,
  SpeakersPage,
  SpeakerDetailsPage,
  AccountPage,
  LogoutPage,
  FeedbackPage,
  BlogPage,
  BlogPostDetailsPage,
  GillSteelPage,
  TechnicalLearningPacks,
} from './views/pages'

const authRoutes = {
  element: <AuthLayout />,
  children: [
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'email/verifysuccess',
      element: <RegisterEmailConfirmed />,
    },
    {
      path: 'email/verifyfailed',
      element: <RegisterEmailFailed />,
    },
    {
      path: 'reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: 'password/reset/:token',
      element: <ChangePasswordPage />,
    },
  ],
}

const mainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <MainPage />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
    {
      path: '/logout',
      element: <LogoutPage />,
    },
    {
      path: 'feedback',
      element: <FeedbackPage />,
    },
  ],
}

const loggedRoutes = {
  element: <LoggedLayout />,
  children: [
    {
      path: 'upcoming-webinars',
      element: <UpcomingWebinarsPage />,
    },
    {
      path: 'previous-webinars',
      element: <PreviousWebinarsPage />,
    },
    {
      path: 'inperson-events',
      element: <EventsPage />,
    },
    {
      path: 'gill-steel/webinars',
      element: <GillSteelPage />,
    },
    {
      path: 'gill-steel/technical-learning-packs',
      element: <TechnicalLearningPacks />,
    },
    {
      path: 'previous-webinars/:slug',
      element: <WebinarDetailsPage />,
    },
    {
      path: 'speakers',
      element: <SpeakersPage />,
    },
    {
      path: 'speakers/:slug',
      element: <SpeakerDetailsPage />,
    },
    {
      path: 'account/:page',
      element: <AccountPage />,
    },
    {
      path: 'monthly-digest',
      element: <BlogPage />,
    },
    {
      path: 'monthly-digest/:slug',
      element: <BlogPostDetailsPage />,
    },
  ],
}

export { authRoutes, mainRoutes, loggedRoutes }
