import styled from 'styled-components'
import { media } from '../../../../../styles'

const Description = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  padding: 10rem 0;
  text-align: center;
  position: relative;

  h1.ant-typography {
    ${media.lg`
      font-size: 4rem;
    `}
  }

  ${media.lg`
    padding: 6rem 0 3.4rem 0;
  `}
`

Description.Circle = styled.div`
  position: absolute;
  top: calc(50% - 14rem);
  left: -11.2rem;

  ${media.lg`
    top: 16rem;
    left: -3.6rem;
  `}
`

Description.SemiCircle = styled.div`
  position: absolute;
  right: 6.4rem;
  top: 6.4rem;

  ${media.lg`
    right: -8.8rem;
    top: 14rem;
  `}
`

Description.Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Item = styled.div`
  width: calc(100% / 3);
  padding: 3.6rem 1.6rem;
  position: relative;

  ${media.xl`
    width: calc(100% / 2);
  `}

  ${media.sm`
    width: 100%;
  `}
`

Item.Wrapper = styled.div`
  height: 8.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4.4rem;
`

Item.Image = styled.img`
  width: 18rem;
  max-height: 8.2rem;
`

Item.Number = styled.div`
  color: ${({ theme }) => theme.colors.gray[400]};
  font-weight: 600;
  font-size: 12rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

Item.Text = styled.div`
  font-size: 1.6rem;
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }
`

export { Description, Item }
