import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate, useRoutes, useSearchParams } from 'react-router-dom'
import { authRoutes, mainRoutes, loggedRoutes } from './routes'
import { GlobalStyle } from './styles'

import 'antd/dist/antd.less'
import { useShallowEqualSelector } from './hooks'

const App = () => {
  const routing = useRoutes([authRoutes, mainRoutes, loggedRoutes])
  const location = useLocation()
  const navigate = useNavigate()
  const trackRef = useRef()

  const [searchParam, setSearchParam] = useSearchParams()

  const { profileDetails, isLogged } = useShallowEqualSelector((state) => state.auth)

  useEffect(() => {
    if (window.location.href !== trackRef.current) {
      trackRef.current = window.location.pathname

      if (window.gtag) {
        window.gtag('set', 'page_path', window.location.pathname)
        window.gtag('event', 'page_view')
      }
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.search.includes('is_cancel') && !isLogged) {
      navigate(`/login/${location.search}`)
    }

    if (location.search.includes('is_cancel') && profileDetails?.id) {
      const userId = searchParam.get('user_id')

      if (+userId === +profileDetails.id) {
        navigate(`/upcoming-webinars/${location.search}`)
      } else {
        setSearchParam((prevParams) => prevParams.delete())
      }
    }
  }, [location.search, profileDetails, isLogged])

  return (
    <>
      <GlobalStyle />
      {routing}
    </>
  )
}

export default App
