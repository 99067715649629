import { List as ListLibrary, Card } from 'antd'
import { rgba } from 'polished'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'

const Item = styled(ListLibrary.Item)`
  margin: 0 1rem;

  h4.ant-typography {
    height: 10rem;
    font-size: 2rem;
    margin: 2rem 0 0 0;
  }
`

Item.Inner = styled(Card)`
  width: 100%;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  }

  ${({ variant }) =>
    variant === 'gill' &&
    `
    border: 2px solid #76AED6;

    &:hover {
      background: #EDF6FD;
    }
  `}
`

Item.Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

Item.Header.Name = styled.div`
  display: flex;
  flex-direction: column;
`
Item.Header.Link = styled(Link)`
  color: ${({ theme }) => theme.colors.gray[600]} !important;
`

Item.Header.Company = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  padding-top: 4px;
  text-transform: uppercase;
`

Item.Tag = styled.div`
  margin-top: 2rem;

  .ant-tag {
    border-radius: 2rem;
    display: inline-flex;
    align-items: center;
    gap: 0.6rem;
    font-size: 1rem;
    padding: 0.2rem 0.8rem 0.2rem 0.2rem;
    text-transform: uppercase;
  }
`

Item.Tag.Icon = styled.div`
  width: 2rem;
  height: 2rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Item.Info = styled.div`
  margin-bottom: 1.8rem;
`

Item.TitleWrapper = styled.div`
  height: 12rem;
  margin-bottom: 2rem;
  cursor: pointer;
`

Item.Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  }

  .ant-badge {
    margin-left: 0.8rem;

    &-count {
      background: ${({ theme }) => theme.colors.gray[400]};
      color: ${({ theme }) => theme.colors.gray[600]};
      font-weight: 600;
    }
  }
`

Item.Details.Name = styled.div`
  padding-left: 2.4rem;
  position: relative;
  margin-right: 1rem;
`

Item.Details.Icon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0.2rem;
  transform: translateY(-50%);
`

Item.Details.Label = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
`

Item.Details.Value = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.2rem;
`

Item.Details.Truncate = styled.div`
  max-width: 11rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

Item.Watch = styled.div`
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`

Item.Watch.Icon = styled(ReactSVG)`
  width: 2.4rem;
  height: 2.4rem;

  svg {
    width: 2.4rem;
  }
`

export { Item }
