import React, { useEffect } from 'react'
import { Form, Input, Button, Typography } from 'antd'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { ChangePassword } from './styled'
import { animations } from '../../../assets'
import { regexp } from '../../../helpers'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { MessageProcess } from '../../components'

const { Title, Text, Paragraph } = Typography

const ChangePasswordPage = ({ authChangePassword, resetAuth }) => {
  const navigate = useNavigate()
  const { token } = useParams()
  const [searchParams] = useSearchParams()

  const { isFetchingChangePassword, isChangedPassword } = useShallowEqualSelector((state) => state.auth)

  useEffect(() => {
    return () => {
      resetAuth()
    }
  }, [])

  useDocumentTitle('Change Password')

  const onFinish = (values) =>
    authChangePassword({
      ...values,
      email: searchParams.get('email'),
      token,
    })

  if (isChangedPassword) {
    return (
      <MessageProcess
        title='Reset password successful!'
        animationData={animations.lock}
        details={
          <>
            <Paragraph>
              You can now use your new password to login to your Estate Research Learning Hub account.
            </Paragraph>

            <Button type='primary' style={{ marginTop: '2.6rem' }} onClick={() => navigate('/login')} block>
              Back to log in
            </Button>
          </>
        }
      />
    )
  }

  return (
    <ChangePassword>
      <ChangePassword.Heading>
        <Title level={2}>Change your password</Title>
        <Text>Enter a new password for your Learning Hub account.</Text>
      </ChangePassword.Heading>

      <Form onFinish={onFinish} autoComplete='off' layout='vertical'>
        <Form.Item
          label='Create new password'
          name='password'
          rules={[
            {
              required: true,
              message: 'Please enter your password',
            },
            {
              min: 8,
            },
            {
              pattern: regexp.passwordPattern(),
            },
          ]}
          help='At least 8 characters (lower and uppercase letters, numbers, special characters)'
        >
          <Input.Password placeholder='Password' />
        </Form.Item>

        <Form.Item
          label='Confirm Password'
          name='password_confirmation'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The entered passwords do not match'))
              },
            }),
          ]}
        >
          <Input.Password placeholder='Confirm password' />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ marginTop: '1.6rem' }}
            type='primary'
            htmlType='submit'
            loading={isFetchingChangePassword}
            block
          >
            Create new password
          </Button>
        </Form.Item>
      </Form>
    </ChangePassword>
  )
}

export default ChangePasswordPage
