import { rgba } from 'polished'
import styled from 'styled-components'
import { media } from '../../../styles'

const Speaker = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  position: relative;
`

Speaker.CircleGreen = styled.div`
  position: absolute;
  bottom: 6.4rem;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

Speaker.SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

Speaker.Inner = styled.div`
  padding: 10rem 0;
  position: relative;

  ${media.xl`
    padding: 4rem 0;
  `}
`

Speaker.Header = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3rem;
  margin-bottom: 6.4rem;

  h2.ant-typography {
    ${media.xl`
      font-size: 3.2rem;
    `}
  }

  ${media.xl`
    padding-bottom: 2rem;
    margin-bottom: 3.2rem;
  `}
`

Speaker.Title = styled.div`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3.6rem;
`

Speaker.Details = styled.div`
  position: relative;
  z-index: 1;
`

Speaker.Item = styled.div`
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  box-shadow: 0 4rem 16rem -4.8rem ${({ theme }) => rgba(theme.colors.gray[700], 0.25)};
  padding: 2.4rem;
  display: flex;
  align-items: center;
  gap: 2rem;
`

Speaker.Item.Info = styled.div``

Speaker.Item.Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.8rem 0;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  }

  .ant-badge {
    margin-left: 0.8rem;

    &-count {
      background: ${({ theme }) => theme.colors.gray[400]};
      color: ${({ theme }) => theme.colors.gray[600]};
      font-weight: 600;
    }
  }
`

Speaker.Item.Details.Name = styled.div`
  padding-left: 2.4rem;
  position: relative;
`

Speaker.Item.Details.Icon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`

Speaker.Item.Details.Label = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  text-transform: uppercase;
`

Speaker.Item.Details.Value = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  padding-top: 0.4rem;
`

Speaker.Item.Details.Value = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-top: 0.4rem;
`

Speaker.Details.Pacman = styled.div`
  position: absolute;
  right: 2.4rem;
  top: -2.4rem;
  z-index: 1;
`

Speaker.Details.SemiCircle = styled.div`
  position: absolute;
  right: 2rem;
  bottom: -2rem;
`

Speaker.Details.SemiRing = styled.div`
  position: absolute;
  left: -4rem;
  top: 4rem;
  z-index: 1;
`

Speaker.Description = styled.div`
  border-left: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  padding-left: 6rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;

  & > div:first-child {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 3.5rem;
    color: ${({ theme }) => theme.colors.blue[200]};
  }

  ${media.xl`
    border: 0;
    padding-left: 0;
  `}
`

export { Speaker }
