export const FETCHING_HISTORY_LOG = 'FETCHING_HISTORY_LOG'
export const RECEIVE_HISTORY_LOG = 'RECEIVE_HISTORY_LOG'

export const FETCHING_HISTORY_CPD_LOG = 'FETCHING_HISTORY_CPD_LOG'
export const RECEIVE_HISTORY_CPD_LOG = 'RECEIVE_HISTORY_CPD_LOG'

export const FETCHING_CREATE_CPD_LOG = 'FETCHING_CREATE_CPD_LOG'
export const RECEIVE_CREATE_CPD_LOG = 'RECEIVE_CREATE_CPD_LOG'

export const FETCHING_REMOVE_CPD_LOG = 'FETCHING_REMOVE_CPD_LOG'
export const RECEIVE_REMOVE_CPD_LOG = 'RECEIVE_REMOVE_CPD_LOG'

export const FETCHING_UPDATE_COMMENT_CPD_LOG = 'FETCHING_UPDATE_COMMENT_CPD_LOG'
export const RECEIVE_UPDATE_COMMENT_CPD_LOG = 'RECEIVE_UPDATE_COMMENT_CPD_LOG'

export const FETCHING_STORE_HISTORY_LOG = 'FETCHING_STORE_HISTORY_LOG'
export const RECEIVE_STORE_HISTORY_LOG = 'RECEIVE_STORE_HISTORY_LOG'

export const FETCHING_HISTORY_CPD_LOG_PDF = 'FETCHING_HISTORY_CPD_LOG_PDF'
export const RECEIVE_HISTORY_CPD_LOG_PDF = 'RECEIVE_HISTORY_CPD_LOG_PDF'
