import styled, { css } from 'styled-components'
import { media } from '../../../styles'

const Layout = styled.div`
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || '136rem'};
  margin: 0 auto;
  padding: 0 3.2rem;

  ${({ relative }) =>
    relative &&
    css`
      position: relative;
    `}

  ${media.lg`
    padding: 0 1.6rem;
  `}
`

export { Layout }
