import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../../../styles'

const Register = styled.div`
  max-width: 49rem;
  width: 100%;
`

Register.Back = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.2rem;
  position: absolute;
  top: 3.2rem;
  left: 3.2rem;

  .anticon {
    width: 3.2rem;
    height: 3.2rem;
    border: 0.1rem solid ${({ theme }) => theme.colors.red[200]};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.8rem;
  }

  ${media.lg`
    top: 10.4rem;
    left: 1.6rem;
  `}
`

Register.ToLogin = styled.div`
  margin-top: 1.6rem;
  text-align: center;
`

Register.Heading = styled.div`
  margin-bottom: 3.6rem;

  h2.ant-typography {
    ${media.lg`
      font-size: 2.8rem;
    `}
  }

  span.ant-typography {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
  }

  ${media.lg`
    margin-bottom: 2.8rem;
  `}
`

Register.FormItem = styled.span`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
`
Register.HelpText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  margin-bottom: 2rem;
  line-height: 1.6rem;
  font-size: 1.2rem;
  font-weight: 400;
`

Register.Message = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colors.gray[600]};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
`

export { Register }
