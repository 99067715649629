import React, { useCallback } from 'react'
import SpeakersPage from './SpeakersPage'
import { useActions } from '../../../hooks'
import { speakers as speakersActions } from '../../../store/actions'

const SpeakersPageContainer = () => {
  const [speakersDispatch] = useActions([speakersActions])
  const getSpeakersList = useCallback(speakersDispatch.getSpeakersList, [speakersDispatch])

  const props = {
    getSpeakersList,
  }

  return <SpeakersPage {...props} />
}

export default SpeakersPageContainer
