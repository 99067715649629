import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Dropdown, Menu, Typography } from 'antd'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { Webinars } from './styled'
import { images } from '../../../assets'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { Ellipse, LayoutCentering, PaginationDots, WebinarsUpcomingWideList } from '../../components'

const { Title } = Typography

const WebinarsPage = ({ getWebinarsUpcoming, getRegionsList, registerToAllWebinars, cancelWebinarRegistration }) => {
  const location = useLocation()

  const [searchParam, setSearchParam] = useSearchParams()

  const { isFetchingUpcomingWebinars, upcomingWebinars } = useShallowEqualSelector((state) => state.webinars)
  const { list: regionsList } = useShallowEqualSelector((state) => state.regions)

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    getWebinarsUpcoming()
    getRegionsList()
  }, [])

  useAsync(async () => {
    if (location.search.includes('?is_cancel=') && upcomingWebinars.list.length > 0) {
      const webinarId = searchParam.get('is_cancel')

      const findWebinar = upcomingWebinars.list.find((webinar) => +webinar.id === +webinarId)

      if (!findWebinar) {
        setSearchParam((prevParams) => prevParams.delete())
      }

      if (findWebinar && findWebinar?.register_status === 2) {
        await cancelWebinarRegistration(webinarId)
        setSearchParam((prevParams) => prevParams.delete())
      } else {
        setSearchParam((prevParams) => prevParams.delete())
      }
    }
  }, [location.search, upcomingWebinars])

  const listenerToScroll = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  useEffect(() => {
    if (visible) {
      window.addEventListener('scroll', listenerToScroll, true)
    } else {
      window.removeEventListener('scroll', listenerToScroll, true)
    }
    return () => window.removeEventListener('scroll', listenerToScroll, true)
  }, [visible])

  useDocumentTitle('Upcoming Webinars')

  const handleDropdown = () => {
    setVisible((prev) => !prev)
  }

  const isDisabled = useMemo(() => {
    return upcomingWebinars.list.every((webinar) => webinar.register_status !== 0)
  }, [upcomingWebinars.list])

  const dropdownLinks = useMemo(() => {
    // eslint-disable-next-line unicorn/no-array-reduce
    const upcomingWebinarsRegions = upcomingWebinars.list.reduce((acc, item) => {
      if (acc.includes(item.region.id)) {
        return acc
      }
      acc.push(item.region.id)
      return acc
    }, [])

    return regionsList.map((region) => ({
      ...region,
      isActive: upcomingWebinarsRegions.includes(region.id),
    }))
  }, [upcomingWebinars.list, regionsList])

  const handleRegisterToWebinars = ({ key }) => {
    registerToAllWebinars(key)
    setVisible(false)
  }

  return (
    <Webinars>
      <Webinars.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </Webinars.CircleGreen>

      <Webinars.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Webinars.SemiRing>

      <Webinars.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Webinars.SemiCircle>

      <LayoutCentering>
        <Webinars.Header>
          <Title level={2}>Upcoming Webinars</Title>

          <Dropdown
            type='primary'
            trigger={['click']}
            overlayClassName='ant-dropdown-trigger'
            disabled={isDisabled}
            visible={visible}
            onVisibleChange={handleDropdown}
            overlay={
              <Menu onClick={handleRegisterToWebinars}>
                {dropdownLinks.map((region) => (
                  <Menu.Item key={region.id} disabled={!region.isActive}>
                    Register to all {region.title}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button className='ant-btn ant-dropdown-trigger-custom'>
              Register for all <Webinars.ChevronIcon rotate={visible} />
            </Button>
          </Dropdown>
        </Webinars.Header>

        <WebinarsUpcomingWideList loading={isFetchingUpcomingWebinars} dataSource={upcomingWebinars.list} />
        <PaginationDots onRequest={getWebinarsUpcoming} meta={upcomingWebinars.meta} isScrollToTop />

        {upcomingWebinars.list.length === 0 && !isFetchingUpcomingWebinars && (
          <Webinars.Empty>
            <Webinars.Empty.Icon src={images.iconEmptyUpcoming} />

            <Webinars.Empty.Inner>
              <Webinars.Empty.Title>Sorry, there are currently no upcoming webinars scheduled.</Webinars.Empty.Title>
              Please check back soon.
            </Webinars.Empty.Inner>
          </Webinars.Empty>
        )}
      </LayoutCentering>
    </Webinars>
  )
}

export default WebinarsPage
