import React from 'react'
import { Grid } from 'antd'
import { WebinarPreviousItem } from '..'
import { List } from './styled'

const { useBreakpoint } = Grid

const WebinarsPreviousList = ({ loading, dataSource }) => {
  const screens = useBreakpoint()

  return (
    <List
      grid={{
        gutter: [32, screens.xs ? 12 : 32],
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 3,
      }}
      loading={loading}
      dataSource={dataSource}
      renderItem={(item) => <WebinarPreviousItem item={item} />}
    />
  )
}

export default WebinarsPreviousList
