import { speakers as consts } from '../constants'

const initialState = {
  isFetchingSpeakersList: false,
  speakers: {
    list: [],
    meta: {},
  },

  isFetchingSpeakersListFull: false,
  speakersFull: [],

  isFetchingFeaturedSpeakersList: false,
  featuredSpeakers: {
    list: [],
    meta: {},
  },

  isFetchingSpeakerDetails: false,
  speakerDetails: {},
}

// eslint-disable-next-line default-param-last
const speakersReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCHING_SPEAKERS_LIST: {
      return {
        ...state,
        isFetchingSpeakersList: action.payload,
      }
    }

    case consts.RECEIVE_SPEAKERS_LIST: {
      return {
        ...state,
        isFetchingSpeakersList: false,
        speakers: action.payload,
      }
    }

    case consts.FETCHING_FEATURED_SPEAKERS_LIST: {
      return {
        ...state,
        isFetchingFeaturedSpeakersList: action.payload,
      }
    }

    case consts.RECEIVE_FEATURED_SPEAKERS_LIST: {
      return {
        ...state,
        isFetchingFeaturedSpeakersList: false,
        featuredSpeakers: action.payload,
      }
    }

    case consts.FETCHING_SPEAKER_DETAILS: {
      return {
        ...state,
        isFetchingSpeakerDetails: action.payload,
      }
    }

    case consts.RECEIVE_SPEAKER_DETAILS: {
      return {
        ...state,
        isFetchingSpeakerDetails: false,
        speakerDetails: action.payload,
      }
    }

    case consts.FETCHING_SPEAKERS_LIST_FULL: {
      return {
        ...state,
        isFetchingSpeakersListFull: action.payload,
      }
    }

    case consts.RECEIVE_SPEAKERS_LIST_FULL: {
      return {
        ...state,
        isFetchingSpeakersListFull: false,
        speakersFull: action.payload.sort(function (a, b) {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        }),
      }
    }

    default: {
      return state
    }
  }
}

export default speakersReducer
