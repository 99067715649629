import React from 'react'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { theme } from './styles'

const queryClient = new QueryClient()

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_VERCEL_ENV,
}

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  })
}

const root = createRoot(document.querySelector('#root'))
root.render(
  <RollbarProvider rollbarConfig={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </QueryClientProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>,
)

reportWebVitals()
