import { storage } from '../../helpers'
import { auth as consts } from '../constants'

const isTokenJWT = storage.getItem('token')

const initialState = {
  isLogged: !!isTokenJWT,

  isFetchingLogin: false,
  isFailedLogin: false,
  errorMsg: null,

  isFetchingProfileDetails: false,
  profileDetails: {},

  isFetchingRegister: false,
  isRegistered: false,

  isFetchingResetPassword: false,
  isResetPassword: false,

  isFetchingChangePassword: false,
  isChangedPassword: false,

  isFetchingUpdateDetails: false,
  isFetchingUpdatePassword: false,
}

// eslint-disable-next-line default-param-last
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.RESET_AUTH_LOGIN: {
      return {
        ...state,
        isRegistered: false,
        isResetPassword: false,
        isChangedPassword: false,
      }
    }

    case consts.RESET_FAILED_AUTH_LOGIN: {
      return {
        ...state,
        isFailedLogin: false,
        errorMsg: null,
      }
    }

    case consts.FAILED_AUTH_LOGIN: {
      return {
        ...state,
        isFailedLogin: true,
        errorMsg: action.payload,
      }
    }

    case consts.FETCHING_AUTH_LOGIN: {
      return {
        ...state,
        isFetchingLogin: action.payload,
      }
    }

    case consts.RECEIVE_AUTH_LOGIN: {
      return {
        ...state,
        isFetchingLogin: false,
        isFailedLogin: false,
        isLogged: true,
        profileDetails: action.payload,
      }
    }

    case consts.FETCHING_AUTH_REGISTER: {
      return {
        ...state,
        isFetchingRegister: action.payload,
      }
    }

    case consts.RECEIVE_AUTH_REGISTER: {
      return {
        ...state,
        isFetchingRegister: false,
        isRegistered: true,
      }
    }

    case consts.FETCHING_AUTH_PROFILE_DETAILS: {
      return {
        ...state,
        isFetchingProfileDetails: action.payload,
      }
    }

    case consts.RECEIVE_AUTH_PROFILE_DETAILS: {
      return {
        ...state,
        isFetchingProfileDetails: false,
        profileDetails: action.payload,
      }
    }

    case consts.FETCHING_UPDATE_PROFILE_DETAILS: {
      return {
        ...state,
        isFetchingUpdateDetails: action.payload,
      }
    }

    case consts.RECEIVE_UPDATE_PROFILE_DETAILS: {
      return {
        ...state,
        isFetchingUpdateDetails: false,
        profileDetails: Object.assign(state.profileDetails, action.payload),
      }
    }

    case consts.FETCHING_UPDATE_PROFILE_PASSWORD: {
      return {
        ...state,
        isFetchingUpdatePassword: action.payload,
      }
    }

    case consts.RECEIVE_UPDATE_PROFILE_PASSWORD: {
      return {
        ...state,
        isFetchingUpdatePassword: false,
      }
    }

    case consts.FETCHING_AUTH_RESET_PASSWORD: {
      return {
        ...state,
        isFetchingResetPassword: action.payload,
      }
    }

    case consts.RECEIVE_AUTH_RESET_PASSWORD: {
      return {
        ...state,
        isFetchingResetPassword: false,
        isResetPassword: true,
      }
    }

    case consts.FETCHING_AUTH_CHANGE_PASSWORD: {
      return {
        ...state,
        isFetchingChangePassword: action.payload,
      }
    }

    case consts.RECEIVE_AUTH_CHANGE_PASSWORD: {
      return {
        ...state,
        isFetchingChangePassword: false,
        isChangedPassword: true,
      }
    }

    case consts.FETCHING_AUTH_LOGOUT: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default authReducer
