import { DownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { media } from '../../../styles'

const Webinars = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  padding: 10rem 0;
  position: relative;

  ${media.xl`
    padding: 4rem 0;
  `}
`

Webinars.CircleGreen = styled.div`
  position: absolute;
  top: 50%;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.SemiCircle = styled.div`
  position: absolute;
  top: 55%;
  right: -4.4rem;

  ${media.xl`
    display: none;
  `}
`

Webinars.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;

  h2.ant-typography {
    ${media.xl`
      font-size: 3.2rem;
    `}
    ${media.lg`
      margin-bottom: 2rem;
  `}
  }

  .ant-dropdown-button {
    .ant-btn {
      text-align: left;

      &:not(.ant-dropdown-trigger) {
        ${media.lg`
          width: 100%;
        `}
      }
    }

    ${media.lg`
      width: 100%;
    `}
  }

  ${media.xl`
    margin-bottom: 2.4rem;
  `}

  ${media.lg`
    flex-direction: column;
  `}
`

Webinars.ChevronIcon = styled(DownOutlined).attrs((props) => ({
  style: {
    transition: 'transform 0.2s ease-out',
    transform: props.rotate ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))``

Webinars.Empty = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  gap: 2.8rem;
  padding: 3.2rem;

  ${media.lg`
    flex-direction: column;
    padding: 3.2rem 2.4rem;
    text-align: center;
  `}
`

Webinars.Empty.Icon = styled.div`
  width: 6.4rem;
  height: 5rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Webinars.Empty.Inner = styled.div`
  color: ${({ theme }) => theme.colors.blue[200]};
`

Webinars.Empty.Title = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
`

export { Webinars }
