import React from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Form, Input, Button, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { Login } from './styled'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'

const { Title, Text } = Typography

const LoginPage = ({ authLogin, resetFailedLogin }) => {
  const { isFetchingLogin, isFailedLogin, errorMsg } = useShallowEqualSelector((state) => state.auth)

  useDocumentTitle('Login')

  const handleChangeFields = () => {
    if (isFailedLogin) {
      resetFailedLogin()
    }
  }

  const onFinish = (values) => authLogin(values)

  return (
    <Login>
      <Login.Back to='/'>
        <LeftOutlined /> Go back
      </Login.Back>

      <Login.Heading>
        <Title level={2}>Log in to your account</Title>
        <Text>Continue your professional learning with Estate Research.</Text>
      </Login.Heading>

      <Form onFinish={onFinish} onFieldsChange={handleChangeFields} autoComplete='off' layout='vertical'>
        <Form.Item
          label='Email address'
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please enter your email',
            },
          ]}
        >
          <Input placeholder='Enter your email address' />
        </Form.Item>

        {isFailedLogin && <Login.Error>{errorMsg}</Login.Error>}

        <Form.Item
          label='Password'
          name='password'
          rules={[
            {
              required: true,
              message: 'Please enter your password',
            },
          ]}
          extra={<Link to='/reset-password'>Forgot your password?</Link>}
        >
          <Input.Password placeholder='Password' />
        </Form.Item>

        <Form.Item style={{ marginTop: '4rem' }}>
          <Button type='primary' htmlType='submit' loading={isFetchingLogin} block>
            Log in
          </Button>
        </Form.Item>

        <Login.ToRegister>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Don't have an account?{' '}
          <Text strong>
            <Link to='/register'>Register</Link>
          </Text>
        </Login.ToRegister>
      </Form>
    </Login>
  )
}

export default LoginPage
