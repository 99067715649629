import styled from 'styled-components'
import { media } from '../../../styles'

const Wrapper = styled.div`
  width: 100%;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.gray[100]};
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3.2rem;
  position: relative;
  z-index: 1;

  .anticon {
    color: ${({ theme }) => theme.colors.yellow[200]};
    font-size: 1.8rem;
  }

  strong {
    font-weight: 600;
  }

  a {
    color: ${({ theme }) => theme.colors.yellow[200]};
    text-decoration: underline;
  }

  ${media.xl`
    height: auto;
    padding: 1.2rem 1.6rem;
    justify-content: flex-start;
  `}
`

export { Wrapper }
