import React from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import {
  MainBanner,
  MainCategories,
  MainFeaturedSpeakers,
  MainFeaturedWebinars,
  MainMedia,
  MainRegister,
  MainUpcomingWebinars,
  MainWorkDescription,
} from './components'
import MainHighlights from './components/MainHighlights'
import { Main } from './styled'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'

const { Title, Paragraph } = Typography

const MainPage = () => {
  const { isLogged } = useShallowEqualSelector((state) => state.auth)
  const { counterStats } = useShallowEqualSelector((state) => state.counter)

  useDocumentTitle('Home')

  if (!isLogged) {
    return (
      <Main>
        <MainBanner
          title='Estate Research Learning Hub'
          details={
            <>
              Estate Research are a leading firm of International Probate Genealogists that also provide{' '}
              <strong>FREE</strong> online training presented by legal experts.
            </>
          }
          isRegister
        />

        <MainMedia
          videoId='698573426'
          details={
            <>
              <Title level={4}>
                The Learning hub offers <strong>{counterStats.webinar_count || 0} FREE online webinars</strong>{' '}
                presented by <strong>{counterStats.speakers_count || 0} different speakers</strong>, each sharing their
                expert knowledge for Private Client practitioners across the UK.
              </Title>
              <Paragraph>
                Create an account to gain access to all previous webinars, register for upcoming live events and keep
                track of your learning via our virtual CPD log.
              </Paragraph>
            </>
          }
        />
        <MainHighlights />
        <MainWorkDescription />
        <MainRegister />
      </Main>
    )
  }

  return (
    <Main>
      <MainBanner
        title='Estate Research Learning Hub'
        details={
          <>
            <strong>{counterStats.webinar_count || 0} FREE</strong> online training sessions presented by more than{' '}
            <strong>{counterStats.speakers_count || 0} expert speakers</strong> all sharing their specialist knowledge
            for Private Client practitioners.
          </>
        }
      />

      <MainMedia
        videoId='698573426'
        details={
          <>
            <Title level={2}>How it works</Title>
            <Paragraph type='secondary' style={{ lineHeight: '2.8rem', marginBottom: '2.8rem' }}>
              Browse via speaker, region, or category to find the best webinar or register to watch upcoming live events
              to support your continued professional development.
            </Paragraph>
            <Paragraph>
              Use the{' '}
              <Link to='/account/cpd-log'>
                <strong>CPD log</strong>
              </Link>{' '}
              as a simple tool to track and record your learning.
            </Paragraph>
          </>
        }
      />
      <MainHighlights />
      <MainUpcomingWebinars />
      <MainFeaturedWebinars />
      <MainCategories />
      <MainFeaturedSpeakers />
    </Main>
  )
}

export default MainPage
