export const FETCHING_SPEAKERS_LIST = 'FETCHING_SPEAKERS_LIST'
export const RECEIVE_SPEAKERS_LIST = 'RECEIVE_SPEAKERS_LIST'

export const FETCHING_SPEAKERS_LIST_FULL = 'FETCHING_SPEAKERS_LIST_FULL'
export const RECEIVE_SPEAKERS_LIST_FULL = 'RECEIVE_SPEAKERS_LIST_FULL'

export const FETCHING_FEATURED_SPEAKERS_LIST = 'FETCHING_FEATURED_SPEAKERS_LIST'
export const RECEIVE_FEATURED_SPEAKERS_LIST = 'RECEIVE_FEATURED_SPEAKERS_LIST'

export const FETCHING_SPEAKER_DETAILS = 'FETCHING_SPEAKER_DETAILS'
export const RECEIVE_SPEAKER_DETAILS = 'RECEIVE_SPEAKER_DETAILS'
