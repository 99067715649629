import React, { useCallback } from 'react'
import Header from './Header'
import { useActions } from '../../../hooks'
import { auth as authActions, counter as counterActions, panels as panelsActions } from '../../../store/actions'

const HeaderContainer = () => {
  const [authDispatch] = useActions([authActions])
  const authLogout = useCallback(authDispatch.authLogout, [authDispatch])
  const getProfileDetails = useCallback(authDispatch.getProfileDetails, [authDispatch])

  const [counterDispatch] = useActions([counterActions])
  const getCounterStats = useCallback(counterDispatch.getCounterStats, [counterDispatch])

  const [panelsDispatch] = useActions([panelsActions])
  const getPanelsSettings = useCallback(panelsDispatch.getPanelsSettings, [panelsDispatch])

  const props = {
    getProfileDetails,
    authLogout,
    getCounterStats,
    getPanelsSettings,
  }

  return <Header {...props} />
}

export default HeaderContainer
