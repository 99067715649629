import React, { useCallback } from 'react'
import SpeakerDetailsPage from './SpeakerDetailsPage'
import { useActions } from '../../../hooks'
import { speakers as speakersActions } from '../../../store/actions'

const SpeakerDetailsPageContainer = () => {
  const [speakersDispatch] = useActions([speakersActions])
  const getSpeakerDetails = useCallback(speakersDispatch.getSpeakerDetails, [speakersDispatch])

  const props = {
    getSpeakerDetails,
  }

  return <SpeakerDetailsPage {...props} />
}

export default SpeakerDetailsPageContainer
