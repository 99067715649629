import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'
import { Item } from '../../../../components/SpeakerItem/styled'

const FeaturedSpeakers = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[300]};
  padding: 10rem 0;
  position: relative;

  ${media.lg`
    padding: 4rem 0;
  `}
`

FeaturedSpeakers.SemiRingCircle = styled.div`
  position: absolute;
  top: 6rem;
  right: -2.8rem;

  ${media.lg`
    display: none;
  `}
`

FeaturedSpeakers.SemiCircle = styled.div`
  position: absolute;
  left: -8rem;
  top: 50%;
  transform: translateY(-50%);

  ${media.lg`
    display: none;
  `}
`

FeaturedSpeakers.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2.ant-typography {
    ${media.lg`
      font-size: 3.2rem;
    `}
  }
`

FeaturedSpeakers.List = styled.div`
  .slick-slider {
    .slick-list {
      padding: 6.4rem 0 !important;
      max-height: 55rem;

      ${media.lg`
        margin-left: 0;
        padding: 0 !important;
      `}
    }

    .slick-slide {
      margin: 0 0.6rem;
      padding: 0 1rem;

      ${media.lg`
        margin: 0;
        padding: 0;
      `};
    }

    ${media.lg`
        margin: 0;
      `};
  }

  .slick-dots {
    margin-top: 0;

    ${media.lg`
        margin-top: 3.2rem;
      `}
  }
}

${media.lg`
    padding: 1.6rem;
    margin-right: -1.6rem;
  `}
`

FeaturedSpeakers.Item = styled.div`
  width: 29.6rem !important;

  & .ant-avatar.ant-avatar-circle.ant-avatar-image {
    width: 6rem !important;
    height: 6rem !important;
  }

  &:hover {
    ${Item.Inner} {
      background-color: ${({ theme }) => theme.colors.red[200]};
      box-shadow: none;
      transform: scale(1.05);

      ${Item.Body},
      ${Item.Region.Value},
      .ant-typography,
      .ant-btn {
        filter: brightness(0) invert(1);
      }

      ${Item.Region.Icon} {
        background-image: url(${images.iconRegionYellow});
      }

      ${media.lg`
        transform: scale(1);
      `}
    }
  }

  ${media.lg`
    width: 100% !important;
    padding-right: 1.6rem;
  `}
`

FeaturedSpeakers.More = styled.div`
  padding: 0 1.6rem;
  margin-top: 2.4rem;
`

export { FeaturedSpeakers }
