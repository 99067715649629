import { message } from 'antd'
import { storage } from '../../helpers'
import { api } from '../../services'
import { auth as consts } from '../constants'

const authLogin = (body) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_AUTH_LOGIN,
      payload: true,
    })

    const { data } = await api.post('/login', body)

    storage.setItem('token', data.token)

    dispatch({
      type: consts.RECEIVE_AUTH_LOGIN,
      payload: data.user,
    })

    if (!window.location.search.includes('is_cancel=')) {
      window.location.replace('/')
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: consts.FAILED_AUTH_LOGIN,
        payload: error.response.data.message,
      })
    }

    dispatch({
      type: consts.FETCHING_AUTH_LOGIN,
      payload: false,
    })
  }
}

const authRegister = (body) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_AUTH_REGISTER,
      payload: true,
    })

    await api.post('/register', body)

    dispatch({
      type: consts.RECEIVE_AUTH_REGISTER,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_AUTH_REGISTER,
      payload: false,
    })
  }
}

const authResetPassword = (body) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_AUTH_RESET_PASSWORD,
      payload: true,
    })

    await api.post('/password/email', body)

    dispatch({
      type: consts.RECEIVE_AUTH_RESET_PASSWORD,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_AUTH_RESET_PASSWORD,
      payload: false,
    })
  }
}

const authChangePassword = (body) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_AUTH_CHANGE_PASSWORD,
      payload: true,
    })

    await api.post('/password/reset', body)

    dispatch({
      type: consts.RECEIVE_AUTH_CHANGE_PASSWORD,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_AUTH_CHANGE_PASSWORD,
      payload: false,
    })
  }
}

const getProfileDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_AUTH_PROFILE_DETAILS,
      payload: true,
    })

    const { data } = await api.get('/profile')

    dispatch({
      type: consts.RECEIVE_AUTH_PROFILE_DETAILS,
      payload: data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_AUTH_PROFILE_DETAILS,
      payload: false,
    })
  }
}

const updateProfileDetails = (body) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_UPDATE_PROFILE_DETAILS,
      payload: true,
    })

    const { data } = await api.post('/profile', body)

    message.success('Profile updated successfully')

    if (body.emailChanged) {
      dispatch({
        type: consts.FETCHING_AUTH_LOGOUT,
        payload: true,
      })

      storage.removeItem('token')

      window.location.replace('/logout')
    } else {
      dispatch({
        type: consts.RECEIVE_UPDATE_PROFILE_DETAILS,
        payload: data,
      })
    }
  } catch {
    dispatch({
      type: consts.FETCHING_UPDATE_PROFILE_DETAILS,
      payload: false,
    })
  }
}

const updateProfilePassword = (body) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_UPDATE_PROFILE_PASSWORD,
      payload: true,
    })

    await api.post('/password/change', body)

    message.success('Password updated successfully')

    dispatch({
      type: consts.RECEIVE_UPDATE_PROFILE_PASSWORD,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_UPDATE_PROFILE_PASSWORD,
      payload: false,
    })
  }
}

const authLogout = () => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_AUTH_LOGOUT,
      payload: true,
    })

    await api.post('/logout')

    storage.removeItem('token')

    window.location.replace('/logout')
  } catch {
    dispatch({
      type: consts.FETCHING_AUTH_LOGOUT,
      payload: false,
    })
  }
}

const resetFailedLogin = () => (dispatch) => {
  dispatch({
    type: consts.RESET_FAILED_AUTH_LOGIN,
  })
}

const resetAuth = () => (dispatch) => {
  dispatch({
    type: consts.RESET_AUTH_LOGIN,
  })
}

export {
  authLogin,
  authRegister,
  authResetPassword,
  authChangePassword,
  getProfileDetails,
  updateProfileDetails,
  updateProfilePassword,
  authLogout,
  resetFailedLogin,
  resetAuth,
}
