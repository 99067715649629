import { rgba } from 'polished'
import styled, { css } from 'styled-components'
import { media } from '../../../styles'

const Account = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  padding: 10rem 0;
  position: relative;
  height: 100%;
  ${media.xl`
    padding: 4rem 0;
  `}

  ${media.xs`
    padding: 3.4rem 0;
  `}
`

Account.CircleViolet = styled.div`
  position: absolute;
  right: -8rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

Account.SemiRing = styled.div`
  position: absolute;
  top: 50%;
  left: -14rem;

  ${media.xl`
    display: none;
  `}
`

Account.SemiCircle = styled.div`
  position: absolute;
  top: 55%;
  right: -6.4rem;

  ${media.xl`
    display: none;
  `}
`

Account.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  h2.ant-typography {
    ${media.xl`
      font-size: 3.2rem;
    `}
  }

  ${media.xl`
    margin-bottom: 0.8rem;
  `}
`

const Menu = styled.div``

Menu.Item = styled.div`
  border-radius: 0.4rem;
  position: relative;
  margin-top: 0.1rem;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray[400]};
    width: 100%;
    height: 0.1rem;
    bottom: 0;
    left: 0;
  }

  a {
    width: 100%;
    color: ${({ theme }) => theme.colors.gray[600]};
    display: block;
    font-weight: 600;
    padding: 1.6rem 1.6rem 1.6rem 4.4rem;
    margin: -0.1rem 0;
    white-space: nowrap;

    &:hover {
      color: ${({ theme }) => theme.colors.red[200]};
      border-radius: 0.4rem;
      background-color: ${({ theme }) => theme.colors.gray[100]}60;
    }
  }

  ${({ active }) =>
    active &&
    css`
      margin-top: 0;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      border-radius: 0.4rem;
      z-index: 1;

      a {
        box-shadow: 0 0.4rem 1.2rem ${({ theme }) => rgba(theme.colors.gray[700], 0.03)};
      }
    `}
`

Menu.Icon = styled.div`
  width: 2rem;
  height: 2rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  position: absolute;
  top: 50%;
  left: 1.6rem;
  transform: translateY(-50%);
`

Menu.Link = styled.a``

export { Account, Menu }
