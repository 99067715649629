import styled from 'styled-components'
import { media } from '../../../styles'

const Wrapper = styled.div`
  width: 100%;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.blue[300]};
  display: flex;
  align-items: center;
  padding: 0 3.2rem;
  position: relative;
  z-index: 1;

  ${media.lg`
    padding: 0 1.6rem;
  `}
`

Wrapper.Link = styled.a`
  font-weight: 600;
  font-size: 1.2rem;

  &:not(:hover) {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`

export { Wrapper }
