import React, { useEffect } from 'react'
import { Button, Grid, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FeaturedSpeakers } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import { CustomCarousel, Ellipse, LayoutCentering, SpeakerItem } from '../../../../components'

const { Title } = Typography
const { useBreakpoint } = Grid

const MainFeaturedSpeakers = ({ getSpeakersList }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const { featuredSpeakers } = useShallowEqualSelector((state) => state.speakers)

  useEffect(() => {
    getSpeakersList({ featured: true, per_page: 6 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FeaturedSpeakers>
      <FeaturedSpeakers.SemiRingCircle>
        <Ellipse type='semiringCircle' color={theme.colors.violet[200]} size={8} rotate={-145} />
      </FeaturedSpeakers.SemiRingCircle>

      <FeaturedSpeakers.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </FeaturedSpeakers.SemiCircle>

      <LayoutCentering>
        <FeaturedSpeakers.Header>
          <Title level={2}>Featured Speakers</Title>

          {screens.lg && (
            <Button type='primary' onClick={() => navigate('/speakers')}>
              View all speakers
            </Button>
          )}
        </FeaturedSpeakers.Header>
      </LayoutCentering>

      <FeaturedSpeakers.List>
        <LayoutCentering relative>
          <CustomCarousel slidesToShow={screens.md ? 2 : 1} infinite variableWidth={screens.lg}>
            {featuredSpeakers.list.map((item) => (
              <FeaturedSpeakers.Item key={item.id}>
                <SpeakerItem item={item} />
              </FeaturedSpeakers.Item>
            ))}
          </CustomCarousel>
        </LayoutCentering>
      </FeaturedSpeakers.List>

      {!screens.lg && (
        <FeaturedSpeakers.More>
          <Button type='primary' onClick={() => navigate('/speakers')} block>
            View all speakers
          </Button>
        </FeaturedSpeakers.More>
      )}
    </FeaturedSpeakers>
  )
}

export default MainFeaturedSpeakers
