import React from 'react'
import { Layout } from './styled'

const LayoutCentering = ({ children, relative, maxWidth }) => (
  <Layout relative={relative} $maxWidth={maxWidth}>
    {children}
  </Layout>
)

export default LayoutCentering
