import styled from 'styled-components'
import { images } from '../../../../../assets'
import { media } from '../../../../../styles'

const Wrapper = styled.div`
  position: relative;
  height: max-content;
  @media (min-width: 1200px) {
    top: -9.6rem;
    height: max-content;
  }
`

const ModalButton = styled.div`
  padding-bottom: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    justify-content: flex-start;
    padding-bottom: 2.4rem;
  }
`

ModalButton.Icon = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  border: 0.1rem solid rgba(249, 147, 136, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > div {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.red[200]};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
ModalButton.Icon.Inner = styled.div`
  background: url(${images.iconWebinarPlaySmall}) no-repeat center / 2rem;
`

ModalButton.Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 0.8rem;
`

ModalButton.Info.Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grayBlue[300]};
`
ModalButton.Info.Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray[700]};
`

const Log = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayBlue[200]};
  border-radius: 0.8rem;
  padding: 3.2rem;

  h3.ant-typography {
    font-size: 2rem;
    margin-bottom: 2.8rem;
  }

  .ant-tag {
    line-height: 1;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.3rem 0.8rem;
  }

  ${media.lg`
    padding: 2.4rem;
  `}
`

Log.Filter = styled.div`
  margin-bottom: 2.8rem;

  .ant-picker {
    flex: 1;
  }

  ${media.lg`
    margin-bottom: 2rem;
  `}
`

Log.Filter.Label = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`

Log.Filter.Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`

Log.Filter.Divider = styled.div`
  color: ${({ theme }) => theme.colors.grayBlue[300]};
`

Log.Selected = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[300]} !important;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.6rem;
  margin-bottom: 0.8rem;

  & > :first-child {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
  }

  ${media.lg`
    flex-direction: column;
    align-items: flex-start;
  `}
`

Log.Selected.Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  .ant-btn {
    height: 3.6rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 1.2rem;

    ${media.lg`
      flex: auto;
      padding-left: 0;
      padding-right: 0;
    `}
  }

  ${media.lg`
    width: 100%;
    margin-top: 0.8rem;
  `}
`

Log.Create = styled.a`
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[300]};
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  gap: 1.4rem;
  padding: 0.8rem 1.6rem;
  margin-top: 0.8rem;

  .anticon {
    font-size: 2rem;
  }
`

Log.Remove = styled.a`
  width: 1.6rem;
  height: 1.6rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
  display: block;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

Log.Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

Log.ManualTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1.2rem;

  & span {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme }) => theme.colors.green[300]};
  }
`

Log.Action = styled.div`
  display: inline-block;
  min-width: 1.6rem;
  height: 1.6rem;
  background: url(${images.iconProfileManual}) no-repeat center;
  border-radius: 50%;
  margin-right: 0.5rem;
`

export { Log, Wrapper, ModalButton }
