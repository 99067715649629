import { webinars as consts } from '../constants'

const initialState = {
  isFetchingUpcomingWebinars: false,
  upcomingWebinars: {
    list: [],
    meta: {},
  },

  isFetchingRecordedWebinars: false,
  recordedWebinars: {
    list: [],
    meta: {},
  },

  isFetchingRecordedWebinarsOfSpeakers: false,
  recordedWebinarsOfSpeakers: {
    list: [],
    meta: {},
  },

  isFetchingInProgressWebinars: false,
  inProgressWebinars: {
    list: [],
    meta: {},
  },

  isFetchingWebinarDetails: false,
  webinarDetails: {},

  isFetchingRegisterToWebinar: false,

  isFetchingRegisterToAllWebinars: false,

  isFetchingCancelWebinarRegistration: false,
}

// eslint-disable-next-line default-param-last
const webinarsReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCHING_WEBINARS_UPCOMING: {
      return {
        ...state,
        isFetchingUpcomingWebinars: action.payload,
      }
    }

    case consts.RECEIVE_WEBINARS_UPCOMING: {
      return {
        ...state,
        isFetchingUpcomingWebinars: false,
        upcomingWebinars: action.payload,
      }
    }

    case consts.FETCHING_WEBINARS_RECORDED: {
      return {
        ...state,
        isFetchingRecordedWebinars: action.payload,
      }
    }

    case consts.RECEIVE_WEBINARS_RECORDED: {
      return {
        ...state,
        isFetchingRecordedWebinars: false,
        recordedWebinars: action.payload,
      }
    }

    case consts.FETCHING_WEBINARS_RECORDED_OF_SPEACKERS: {
      return {
        ...state,
        isFetchingRecordedWebinarsOfSpeakers: action.payload,
      }
    }

    case consts.RECEIVE_WEBINARS_RECORDED_OF_SPEACKERS: {
      return {
        ...state,
        isFetchingRecordedWebinarsOfSpeakers: false,
        recordedWebinarsOfSpeakers: action.payload,
      }
    }

    case consts.FETCHING_WEBINARS_INPROGRESS: {
      return {
        ...state,
        isFetchingInProgressWebinars: action.payload,
      }
    }

    case consts.RECEIVE_WEBINARS_INPROGRESS: {
      return {
        ...state,
        isFetchingInProgressWebinars: false,
        inProgressWebinars: action.payload,
      }
    }

    case consts.FETCHING_WEBINAR_DETAILS: {
      return {
        ...state,
        isFetchingWebinarDetails: action.payload,
      }
    }

    case consts.RECEIVE_WEBINAR_DETAILS: {
      return {
        ...state,
        isFetchingWebinarDetails: false,
        webinarDetails: action.payload,
      }
    }

    case consts.FETCHING_REGISTER_TO_WEBINAR: {
      return {
        ...state,
        isFetchingRegisterToWebinar: action.payload.isFetch,
        upcomingWebinars: {
          ...state.upcomingWebinars,
          list: state.upcomingWebinars.list.map((item) => {
            if (item.id === +action.payload.id) {
              return {
                ...item,
                register_status: action.payload.register_status,
              }
            }

            return item
          }),
        },
      }
    }

    case consts.RECEIVE_REGISTER_TO_WEBINAR: {
      return {
        ...state,
        isFetchingRegisterToWebinar: false,
        upcomingWebinars: {
          ...state.upcomingWebinars,
          list: state.upcomingWebinars.list.map((item) => {
            if (item.id === +action.payload.id) {
              return {
                ...item,
                register_status: 2,
              }
            }

            return item
          }),
        },
      }
    }

    case consts.FETCHING_REGISTER_TO_ALL_WEBINARS: {
      return {
        ...state,
        isFetchingRegisterToAllWebinars: action.payload,
      }
    }

    case consts.RECEIVE_REGISTER_TO_ALL_WEBINARS: {
      return {
        ...state,
        isFetchingRegisterToAllWebinars: false,
        upcomingWebinars: {
          ...state.upcomingWebinars,
          list: state.upcomingWebinars.list.map((item) => {
            if (+item.region.id === +action.payload.id) {
              return {
                ...item,
                register_status: 2,
              }
            }
            return item
          }),
        },
      }
    }

    case consts.FETCHING_CANCEL_WEBINAR_REGISTRATION: {
      return {
        ...state,
        isFetchingCancelWebinarRegistration: action.payload,
      }
    }

    case consts.RECEIVE_CANCEL_WEBINAR_REGISTRATION: {
      return {
        ...state,
        isFetchingCancelWebinarRegistration: false,
        upcomingWebinars: {
          ...state.upcomingWebinars,
          list: state.upcomingWebinars.list.map((item) => {
            if (item.id === +action.payload.id) {
              return {
                ...item,
                register_status: 0,
              }
            }

            return item
          }),
        },
      }
    }

    default: {
      return state
    }
  }
}

export default webinarsReducer
