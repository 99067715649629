import React, { useEffect } from 'react'
import { Typography } from 'antd'
import { PreviousWebinars } from './styled'
import { useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import { LayoutCentering, Ellipse, WebinarsPreviousList, PaginationDots } from '../../../../components'

const { Title } = Typography

const OtherPreviousWebinars = ({ slug, getWebinarsRecordedOfSpeakers }) => {
  const { isFetchingRecordedWebinarsOfSpeakers, recordedWebinarsOfSpeakers } = useShallowEqualSelector(
    (state) => state.webinars,
  )
  const requestParams = { speakers: [slug], per_page: 6 }

  useEffect(() => {
    getWebinarsRecordedOfSpeakers(requestParams)
  }, [slug])

  if (recordedWebinarsOfSpeakers.list.length === 0) {
    return null
  }

  return (
    <PreviousWebinars>
      <PreviousWebinars.SemiRing>
        <Ellipse type='semiring' color={theme.colors.red[200]} size={18} rotate={-45} />
      </PreviousWebinars.SemiRing>

      <PreviousWebinars.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </PreviousWebinars.CircleGreen>

      <PreviousWebinars.CircleViolet>
        <Ellipse type='circle' color={theme.colors.violet[200]} size={15.3} />
      </PreviousWebinars.CircleViolet>

      <PreviousWebinars.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </PreviousWebinars.SemiCircle>

      <LayoutCentering>
        <PreviousWebinars.Header>
          <Title level={2}>Previous Webinars</Title>
        </PreviousWebinars.Header>

        <WebinarsPreviousList
          loading={isFetchingRecordedWebinarsOfSpeakers}
          dataSource={recordedWebinarsOfSpeakers.list}
        />
        <PaginationDots
          onRequest={getWebinarsRecordedOfSpeakers}
          meta={recordedWebinarsOfSpeakers.meta}
          customParams={requestParams}
        />
      </LayoutCentering>
    </PreviousWebinars>
  )
}

export default OtherPreviousWebinars
