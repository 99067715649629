import React from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Logout } from './styled'
import { animations } from '../../../assets'
import { useDocumentTitle } from '../../../hooks'
import { MessageProcess } from '../../components'

const LogoutPage = () => {
  const navigate = useNavigate()

  useDocumentTitle('Logout')

  const Description = styled.span`
    color: ${({ theme }) => theme.colors.gray[600]};
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
  `

  return (
    <Logout>
      <MessageProcess
        title='You are now logged out'
        animationData={animations.lock}
        details={
          <>
            <Description>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              We hope to see you again soon. Don't forget to check back often for new upcoming webinars and past
              recordings.
            </Description>

            <Button type='primary' style={{ marginTop: '4rem' }} onClick={() => navigate('/')} block>
              Back to home
            </Button>
          </>
        }
      />
    </Logout>
  )
}

export default LogoutPage
