import React, { useEffect } from 'react'
import { Grid, Row, Col, Typography, Image, Divider } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { StyledContent, StyledEvents } from './styled'
import { images } from '../../../assets'
import LogoProfessionalServicesImg from '../../../assets/images/logo-professional-services.png'
import BadgeProfessionalServicesImg from '../../../assets/images/professional-services-badge.svg'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse, FilterBy, PaginationDots } from '../../components'
import EventItem from '../../components/EventItem'
import { MainMedia } from '../MainPage/components'
import MainFeaturedWebinars from '../MainPage/components/MainFeaturedWebinars'
import { Webinars } from '../UpcomingWebinarsPage/styled'

const { useBreakpoint } = Grid
const { Title } = Typography

const PAGE_TITLE = 'In-Person events'

const EventsPage = ({ getEvents }) => {
  const screens = useBreakpoint()

  const { isFetchingEvents, events } = useShallowEqualSelector((state) => state.events)

  useEffect(() => {
    const parseParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

    getEvents(parseParams)
  }, [])

  useDocumentTitle(PAGE_TITLE)

  return (
    <StyledEvents>
      <StyledEvents.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </StyledEvents.CircleGreen>

      <StyledEvents.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </StyledEvents.SemiRing>

      <StyledEvents.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </StyledEvents.SemiCircle>

      <LayoutCentering>
        <StyledEvents.Header>
          <Title level={2}>{PAGE_TITLE}</Title>
        </StyledEvents.Header>

        <Row gutter={[32, 32]} wrap={!screens.xl} style={{ marginBottom: screens.xl ? 56 : 0 }}>
          <Col {...(screens.xl ? { flex: '292px' } : { xs: 24 })}>
            <FilterBy onRequest={getEvents} context='events' visibleEventsLocations />
          </Col>

          <Col {...(screens.xl ? { flex: 'auto' } : { xs: 24 })}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col md={18}>
                <StyledContent>
                  <Paragraph strong>
                    Each year Estate Research host, attend and sponsor Private Client events around the UK to meet with
                    solicitors in person.
                  </Paragraph>
                  <Paragraph>
                    As well as hosting several Scots Law training events in Scotland, Estate Research are delighted to
                    sponsor all Wills & Probate events hosted by{' '}
                    <Link to='https://proconferences.com/product-category/legal/' target='_blank'>
                      Professional Conferences
                    </Link>{' '}
                    in 2024.
                  </Paragraph>
                  <Paragraph>
                    Professional Conferences have have been the leaders in hosting legal conferences in the UK for more
                    than 30 years, with over 20,000 delegates attending their conferences each year.
                  </Paragraph>
                  <Paragraph>
                    Click the links below to be redirected to the relevant booking page for each event.
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 0 }}>
                    Use the promotional code <b>ESTATERESEARCH10</b> for £10 off any Professional Conference event in
                    2024.
                  </Paragraph>
                </StyledContent>
              </Col>
              <Col xs={0} md={1}>
                <Divider type='vertical' style={{ height: '100%' }} />
              </Col>
              <Col md={5} style={screens.md ? {} : { marginTop: 20 }}>
                <Image style={{ marginBottom: 16 }} preview={false} src={images.logotypeBlack} />
                <Image preview={false} src={LogoProfessionalServicesImg} />
              </Col>
            </Row>

            <StyledEvents.List
              grid={{
                gutter: [32, screens.xs ? 12 : 32],
                xs: 1,
                sm: 2,
                md: 2,
                lg: 2,
                xl: 3,
                xxl: 3,
              }}
              loading={isFetchingEvents}
              dataSource={events.list}
              renderItem={(item) => <EventItem item={item} />}
            />
            <PaginationDots onRequest={getEvents} meta={events.meta} isScrollToTop />

            {events.list.length === 0 && !isFetchingEvents && (
              <Webinars.Empty>
                <Webinars.Empty.Icon src={images.iconEmptyUpcoming} />

                <Webinars.Empty.Inner>
                  <Webinars.Empty.Title>
                    Sorry, there are currently no upcoming in-person events scheduled.
                  </Webinars.Empty.Title>
                  Please check back soon.
                </Webinars.Empty.Inner>
              </Webinars.Empty>
            )}
          </Col>
        </Row>
      </LayoutCentering>

      <MainMedia
        style={{ background: '#fff' }}
        details={
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col md={15}>
              <Typography>
                <Title level={2}>Professional Conferences</Title>
                <Paragraph type='secondary' style={{ lineHeight: '2.8rem', marginBottom: '2.8rem' }}>
                  High-quality, cost-effective conferences for the dental, surveying and legal professions.
                </Paragraph>
                <Paragraph>
                  More than 20,000 delegates attend our conferences each year, and the quality and value for money can
                  be seen in the considerable percentage of our delegates who return to us year after year.
                </Paragraph>
              </Typography>
            </Col>
            <Col md={9} style={screens.md ? {} : { marginTop: 20 }}>
              <a href='https://proconferences.com/' target='_blank' rel='noreferrer' aria-label='Pro Conferences'>
                <Image preview={false} src={BadgeProfessionalServicesImg} />
              </a>
            </Col>
          </Row>
        }
      />

      <MainFeaturedWebinars title='Featured webinars from Estate Research' />
    </StyledEvents>
  )
}

export default EventsPage
