import { categories as consts } from '../constants'

const initialState = {
  isFetchingCategoriesList: false,
  list: [],
}

// eslint-disable-next-line default-param-last
const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCHING_CATEGORIES_LIST: {
      return {
        ...state,
        isFetchingCategoriesList: action.payload,
      }
    }

    case consts.RECEIVE_CATEGORIES_LIST: {
      return {
        ...state,
        isFetchingCategoriesList: false,
        list: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default categoriesReducer
