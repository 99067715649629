import React, { useCallback } from 'react'
import OtherPreviousWebinars from './OtherPreviousWebinars'
import { useActions } from '../../../../../hooks'
import { webinars as webinarsActions } from '../../../../../store/actions'

const OtherPreviousWebinarsContainer = ({ ...rest }) => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsRecordedOfSpeakers = useCallback(webinarsDispatch.getWebinarsRecordedOfSpeakers, [webinarsDispatch])

  const props = {
    getWebinarsRecordedOfSpeakers,
    ...rest,
  }

  return <OtherPreviousWebinars {...props} />
}

export default OtherPreviousWebinarsContainer
