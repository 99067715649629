import React, { useEffect } from 'react'
import { Grid, Row, Col, Typography } from 'antd'
import queryString from 'query-string'
import { WebinarsContinueWatching, WebinarsFeaturedSpeakers } from './components'
import { Webinars } from './styled'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse, FilterBy, WebinarsPreviousList, PaginationDots } from '../../components'

const { useBreakpoint } = Grid
const { Title } = Typography

const PreviousWebinarsPage = ({ getWebinarsRecorded }) => {
  const screens = useBreakpoint()

  const { isFetchingRecordedWebinars, recordedWebinars } = useShallowEqualSelector((state) => state.webinars)

  useEffect(() => {
    const parseParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

    getWebinarsRecorded(parseParams)
  }, [])

  useDocumentTitle('Previous Webinars')

  return (
    <Webinars>
      <Webinars.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </Webinars.CircleGreen>

      <Webinars.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Webinars.SemiRing>

      <Webinars.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Webinars.SemiCircle>

      <LayoutCentering>
        <Webinars.Header>
          <Title level={2}>Previous Webinars</Title>
        </Webinars.Header>

        <Row gutter={[32, 32]} wrap={!screens.xl}>
          <Col {...(screens.xl ? { flex: '292px' } : { xs: 24 })}>
            <FilterBy
              onRequest={getWebinarsRecorded}
              visibleRegions
              visibleCategories
              visibleSpeakers
              context='webinars'
            />
          </Col>

          <Col {...(screens.xl ? { flex: 'auto' } : { xs: 24 })}>
            <Webinars.List>
              <WebinarsPreviousList loading={isFetchingRecordedWebinars} dataSource={recordedWebinars.list} />
              <PaginationDots onRequest={getWebinarsRecorded} meta={recordedWebinars.meta} isScrollToTop />
            </Webinars.List>
          </Col>
        </Row>
      </LayoutCentering>

      <WebinarsContinueWatching />
      <WebinarsFeaturedSpeakers />
    </Webinars>
  )
}

export default PreviousWebinarsPage
