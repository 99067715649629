import React from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { animations } from '../../../assets'
import { useDocumentTitle } from '../../../hooks'
import { MessageProcess } from '../../components'

const RegisterEmailConfirmed = () => {
  const navigate = useNavigate()

  useDocumentTitle('Email Confirmed')

  const Description = styled.span`
    color: ${({ theme }) => theme.colors.gray[600]};
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
  `

  return (
    <MessageProcess
      title='Email confirmed!'
      animationData={animations.check}
      details={
        <>
          <Description>
            Thank you for completing our registration process. Your account has been created and you may now log in to
            access the Estate Research Learning Hub.
          </Description>

          <Button type='primary' style={{ marginTop: '4rem' }} onClick={() => navigate('/login')} block>
            Log in
          </Button>
        </>
      }
    />
  )
}

export default RegisterEmailConfirmed
