import React from 'react'
import { Button, Grid, Typography, Row, Col } from 'antd'
import { ReactSVG } from 'react-svg'
import styled from 'styled-components'
import { images } from '../../../assets'
import { media } from '../../../styles'

const { useBreakpoint } = Grid

const Container = styled.div`
  background: #132b4b;
  border-radius: 8px;
  padding: 32px;
  color: #fff;
  overflow: hidden;
  position: relative;
`

const Circle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: #6bbcb9;
  border-radius: 50%;
  width: 192px;
  height: 192px;
  transform: translate(25%, 35%);

  svg {
    stroke: #fff;
    width: 72px;
    height: 72px;
    top: 33px;
    left: 48px;
    position: absolute;
  }

  ${media.md`
    transform: translate(35%, 45%);

    svg {
      top: 25px;
      left: 35px;
    }
  `}
`

const CallToAction = ({ title, content, button }) => {
  const screens = useBreakpoint()
  return (
    <Container>
      <Row gutter={[32, 32]} align='middle' wrap={!screens.xl}>
        <Col {...(screens.xl ? { flex: '1' } : { xs: 24 })}>
          <div style={{ position: 'relative', zIndex: 1 }}>
            <Typography.Paragraph
              style={{ fontSize: 20, fontWeight: 600, color: '#fff', maxWidth: 265, marginBottom: 20 }}
            >
              {title}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{
                fontSize: 14,
                fontFamily: '"IBM Plex Sans", sans-serif',
                color: '#fff',
                maxWidth: 414,
                marginBottom: 0,
              }}
            >
              {content}
            </Typography.Paragraph>
          </div>
        </Col>
        <Col {...(screens.xl ? { flex: 'none' } : { xs: 24 })}>
          <div style={{ paddingRight: screens.xl ? 163 : undefined }}>
            <Button ghost type='primary' onClick={button.onClick}>
              {button.title}
            </Button>
          </div>
        </Col>

        <Circle>
          <ReactSVG src={images.iconTablerBook2} />
        </Circle>
      </Row>
    </Container>
  )
}

export default CallToAction
