import React, { useEffect } from 'react'
import { CalendarOutlined } from '@ant-design/icons'
import { Col, Divider, Grid, Row, Image } from 'antd'
import { useParams } from 'react-router-dom'
import { BlogPost } from './styled'
import { formatUTC } from '../../../helpers/date'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { Ellipse, LayoutCentering, Loader } from '../../components'
import { BlogPostItem } from '../../components/BlogItemsList/styled'
import LawSkillsImage from '../GillSteelPage/images/logo-lawskills-white.png'

const { useBreakpoint } = Grid

const interval = null

const BlogPostDetailsPage = ({ getBlogPostDetails }) => {
  const params = useParams()
  const screens = useBreakpoint()

  const { isFetchingBlogPostDetails, details } = useShallowEqualSelector((state) => state.blogPosts)

  useEffect(() => {
    getBlogPostDetails(params.slug)

    return () => {
      clearInterval(interval)
    }
  }, [params.slug])

  useDocumentTitle(details.title)

  if (!details.id || isFetchingBlogPostDetails) {
    return <Loader />
  }

  return (
    <>
      <BlogPost.Header>
        <LayoutCentering>
          <BlogPost.Title level={2}>{details.title}</BlogPost.Title>

          <Divider />

          <Row gutter={[16]} align='middle' wrap={screens.xs} justify='space-between'>
            <Col>
              <BlogPostItem.Date>
                <CalendarOutlined /> {formatUTC(details.created_at, 'dd/MM/yyyy')}
              </BlogPostItem.Date>
            </Col>
            <Col>
              <Image src={LawSkillsImage} alt='Law Skills' height={28} preview={false} style={{ width: 'auto' }} />
            </Col>
          </Row>
        </LayoutCentering>
      </BlogPost.Header>
      <BlogPost>
        <BlogPost.Inner>
          <BlogPost.CircleGreen>
            <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
          </BlogPost.CircleGreen>

          <BlogPost.SemiRing>
            <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
          </BlogPost.SemiRing>

          <LayoutCentering>
            <BlogPost.Content dangerouslySetInnerHTML={{ __html: details.description }} />

            {details.html && <BlogPost.Content dangerouslySetInnerHTML={{ __html: details.html }} />}
          </LayoutCentering>
        </BlogPost.Inner>
      </BlogPost>
    </>
  )
}

export default BlogPostDetailsPage
