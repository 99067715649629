import React from 'react'
import { Grid, Button, Typography, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import ComingSoonImage from './images/coming-soon.svg'
import { StyledContainer } from './styled'
import { useDocumentTitle } from '../../../hooks'
import { theme } from '../../../styles'
import { Ellipse } from '../../components'
import GillHeader from '../GillSteelPage/components/GillHeader'
import { NotFound } from '../NotFoundPage/styled'

const { useBreakpoint } = Grid

const ComingSoonPage = ({ title }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  useDocumentTitle(title)

  return (
    <StyledContainer>
      <GillHeader title={title} />
      <NotFound>
        <NotFound.SemiCircle>
          <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={screens.lg ? 8 : 5.6} rotate={-45} />
        </NotFound.SemiCircle>

        <NotFound.SemiRing>
          <Ellipse type='semiring' color={theme.colors.yellow[200]} size={screens.lg ? 25 : 6} rotate={-45} />
        </NotFound.SemiRing>

        <Image src={ComingSoonImage} height={210} preview={false} style={{ marginBottom: 20, width: 'auto' }} />

        <Typography.Title level={2}>Coming Soon</Typography.Title>
        <Typography.Text>Exciting new features ahead! Worth the wait!</Typography.Text>

        <Button type='primary' onClick={() => navigate('/')}>
          Back to homepage
        </Button>
      </NotFound>
    </StyledContainer>
  )
}

export default ComingSoonPage
