import { message } from 'antd'
import queryString from 'query-string'
import { api } from '../../services'
import { webinars as consts } from '../constants'

const getWebinarsUpcoming = (query) => async (dispatch) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_WEBINARS_UPCOMING,
      payload: true,
    })

    const { data } = await api.get(`/webinars/upcoming?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_WEBINARS_UPCOMING,
      payload: {
        list: data.data,
        meta: data.meta,
      },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_WEBINARS_UPCOMING,
      payload: false,
    })
  }
}

const getWebinarsRecorded = (query) => async (dispatch) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_WEBINARS_RECORDED,
      payload: true,
    })

    const { data } = await api.get(`/webinars/recorded?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_WEBINARS_RECORDED,
      payload: {
        list: data.data,
        meta: data.meta,
      },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_WEBINARS_RECORDED,
      payload: false,
    })
  }
}

const getWebinarsInProgress = (query) => async (dispatch) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_WEBINARS_INPROGRESS,
      payload: true,
    })

    const { data } = await api.get(`/webinars/inprogress?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_WEBINARS_INPROGRESS,
      payload: {
        list: data.data,
        meta: data.meta,
      },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_WEBINARS_INPROGRESS,
      payload: false,
    })
  }
}

const getWebinarsRecordedOfSpeakers = (query) => async (dispatch) => {
  const queryParams = queryString.stringify(query, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_WEBINARS_RECORDED_OF_SPEACKERS,
      payload: true,
    })

    const { data } = await api.get(`/webinars/recorded?${queryParams}`)

    dispatch({
      type: consts.RECEIVE_WEBINARS_RECORDED_OF_SPEACKERS,
      payload: {
        list: data.data,
        meta: data.meta,
      },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_WEBINARS_RECORDED_OF_SPEACKERS,
      payload: false,
    })
  }
}

const getWebinarDetails = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_WEBINAR_DETAILS,
      payload: true,
    })

    const { data } = await api.get(`/webinars/${slug}/details`)

    dispatch({
      type: consts.RECEIVE_WEBINAR_DETAILS,
      payload: data.data,
    })
  } catch {
    dispatch({
      type: consts.FETCHING_WEBINAR_DETAILS,
      payload: false,
    })
  }
}

const registerToWebinar = (id) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_REGISTER_TO_WEBINAR,
      payload: { isFetch: true, id, register_status: 1 },
    })

    const { data } = await api.get(`/webinars/${id}/register`)

    dispatch({
      type: consts.RECEIVE_REGISTER_TO_WEBINAR,
      payload: { id },
    })
    message.success(data.message)
  } catch {
    dispatch({
      type: consts.FETCHING_REGISTER_TO_WEBINAR,
      payload: { isFetch: true, id, register_status: 0 },
    })
  }
}

const registerToAllWebinars = (query) => async (dispatch) => {
  const queryParams = queryString.stringify({ regions: [query] }, { arrayFormat: 'bracket' })

  try {
    dispatch({
      type: consts.FETCHING_REGISTER_TO_ALL_WEBINARS,
      payload: true,
    })

    const { data } = await api.get(`/webinars/register-all?${queryParams}`)

    message.success(data.message)

    dispatch({
      type: consts.RECEIVE_REGISTER_TO_ALL_WEBINARS,
      payload: { id: query },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_REGISTER_TO_ALL_WEBINARS,
      payload: false,
    })
  }
}

const cancelWebinarRegistration = (id) => async (dispatch) => {
  try {
    dispatch({
      type: consts.FETCHING_CANCEL_WEBINAR_REGISTRATION,
      payload: true,
    })

    await api.get(`/webinars/${id}/cancel`)

    dispatch({
      type: consts.RECEIVE_CANCEL_WEBINAR_REGISTRATION,
      payload: { id },
    })
  } catch {
    dispatch({
      type: consts.FETCHING_CANCEL_WEBINAR_REGISTRATION,
      payload: false,
    })
  }
}

export {
  getWebinarsUpcoming,
  getWebinarsRecorded,
  getWebinarsInProgress,
  getWebinarsRecordedOfSpeakers,
  getWebinarDetails,
  registerToWebinar,
  registerToAllWebinars,
  cancelWebinarRegistration,
}
