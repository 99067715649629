/* eslint-disable import/prefer-default-export */

export const minutesToReadable = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60

  if (hours === 0) return `${mins}m`

  if (mins === 0) return `${hours}h`

  return `${hours}h ${mins}m`
}
