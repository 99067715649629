import React, { useCallback } from 'react'
import AccountLog from './AccountLog'
import { useActions } from '../../../../../hooks'
import { history as historyActions } from '../../../../../store/actions'

const AccountLogContainer = ({ ...rest }) => {
  const [historyDispatch] = useActions([historyActions])
  const getHistoryCPDLog = useCallback(historyDispatch.getHistoryCPDLog, [historyDispatch])
  const getHistoryCPDLogPdf = useCallback(historyDispatch.getHistoryCPDLogPdf, [historyDispatch])
  const removeCPDLog = useCallback(historyDispatch.removeCPDLog, [historyDispatch])
  const updateCommentCPDLog = useCallback(historyDispatch.updateCommentCPDLog, [historyDispatch])

  const props = {
    getHistoryCPDLog,
    removeCPDLog,
    updateCommentCPDLog,
    getHistoryCPDLogPdf,
    ...rest,
  }

  return <AccountLog {...props} />
}

export default AccountLogContainer
