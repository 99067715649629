import React from 'react'
import { Grid, Typography, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Register } from './styled'
import { theme } from '../../../../../styles'
import { LayoutCentering, Ellipse } from '../../../../components'

const { useBreakpoint } = Grid
const { Title, Paragraph } = Typography

const MainRegister = () => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  return (
    <Register>
      <Register.SemiRing>
        <Ellipse type='semiring' color={theme.colors.red[200]} size={screens.lg ? 18 : 7.3} rotate={-45} />
      </Register.SemiRing>

      <LayoutCentering>
        <Register.Inner>
          <Register.Details>
            <Title>About Estate Research</Title>

            <Title level={5}>
              Established in 2001, Estate Research are a firm of International Probate Genealogists who specialise in
              locating next of kin and missing beneficiaries to estates.
            </Title>

            <Register.Text>
              <Paragraph>
                Since 2020, we have been providing free training to enhance the services we offer to both Wills and
                Probate solicitors and Public sector bodies. These include CPD Webinars and e-Conferences delivered by
                experts in the field - all free of charge.
              </Paragraph>
              <Paragraph>
                For further information about Estate Research Learning Hub or the genealogy services Estate Research
                offer - please email{' '}
                <a href='mailto:info@estateresearchlearninghub.co.uk'>info@estateresearchlearninghub.co.uk</a>
              </Paragraph>
            </Register.Text>
          </Register.Details>

          <Register.Form>
            <Register.Form.Pacman>
              <Ellipse type='pacman' color={theme.colors.yellow[200]} size={6} />
            </Register.Form.Pacman>

            <Register.Form.SemiCircle>
              <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={6} rotate={-45} />
            </Register.Form.SemiCircle>

            <Register.Form.SemiRing>
              <Ellipse type='semiring' color={theme.colors.violet[200]} size={8.8} rotate={-45} />
            </Register.Form.SemiRing>

            <Register.Form.Title>View all of our previous webinars</Register.Form.Title>

            <Register.Form.Text>
              With industry experts for <strong>FREE</strong> and register for our upcoming events.
            </Register.Form.Text>

            <Button type='primary' onClick={() => navigate('/register')} block>
              Create an account
            </Button>
          </Register.Form>
        </Register.Inner>
      </LayoutCentering>
    </Register>
  )
}

export default MainRegister
