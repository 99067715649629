import styled from 'styled-components'
import { media } from '../../../../styles'

// eslint-disable-next-line import/prefer-default-export
export const StyledGillHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  background: #1f3a5f;
  padding: 6.8rem 0;

  h2.ant-typography {
    color: #fff;
    margin: 0;

    ${media.xl`
      font-size: 3.2rem;
    `}
  }

  ${media.xl`
    padding: 3rem 0;
    margin-bottom: 0.8rem;
  `}
`
