import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { List, Icon, Name, Item } from './styled'

const WebinarCategoriesList = ({ dataSource }) => {
  const navigate = useNavigate()

  return (
    <List>
      {dataSource.map((item) => (
        <Item
          key={item.id}
          onClick={() =>
            navigate({
              pathname: 'previous-webinars',
              search: queryString.stringify({ categories: [item.id] }, { arrayFormat: 'bracket' }),
            })
          }
        >
          <Item.Inner>
            <Icon src={item.icon} />
            <Name>{item.title}</Name>
          </Item.Inner>

          <ArrowRightOutlined />
        </Item>
      ))}
    </List>
  )
}

export default WebinarCategoriesList
