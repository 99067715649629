import styled from 'styled-components'
import { media } from '../../../styles'

const ResetPassword = styled.div`
  max-width: 49rem;
  width: 100%;
`

ResetPassword.Heading = styled.div`
  margin-bottom: 6rem;

  h2.ant-typography {
    ${media.lg`
      font-size: 2.8rem;
    `}
  }

  ${media.lg`
    margin-bottom: 2.8rem;
  `}
`

ResetPassword.ToRegister = styled.div`
  margin-top: 2rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  & > :first-child {
    font-weight: 400;
  }
`

export { ResetPassword }
