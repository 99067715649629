import Vimeo from '@u-wave/react-vimeo'
import styled from 'styled-components'
import { media } from '../../../styles'

const Webinar = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  position: relative;
`

Webinar.CircleGreen = styled.div`
  position: absolute;
  top: 50%;
  left: -11rem;

  ${media.xl`
    display: none;
  `}
`

Webinar.SemiRing = styled.div`
  position: absolute;
  right: -10rem;
  top: 6.4rem;

  ${media.xl`
    display: none;
  `}
`

Webinar.Inner = styled.div`
  padding: 10rem 0;
  position: relative;

  ${media.xl`
    padding: 4rem 0;
  `}
`

Webinar.Header = styled.div`
  position: relative;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4rem;
  margin-bottom: 6.4rem;

  h2.ant-typography {
    margin: 0;
    ${media.xl`
      font-size: 3.2rem;
    `}
  }

  ${media.xl`
    padding-bottom: ${({ withTag }) => (withTag ? '3.2rem' : '2rem')};
    margin-bottom: 3.2rem;
  `}

  ${media.lg`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`

Webinar.Tag = styled.div`
  position: relative;

  .ant-tag {
    border-radius: 2rem;
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    font-size: 1rem;
    line-height: 1.4rem;
    padding: 0.4rem 1.2rem 0.4rem 0.4rem;
    text-transform: uppercase;
    margin-left: 1rem;

    ${media.lg`
      margin-top: 1.6rem; 
      margin-left: 0;
  `}
  }
`

Webinar.Tag.Icon = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Webinar.Video = styled(Vimeo)`
  margin: 3.2rem 0;
`

Webinar.Details = styled.div`
  position: relative;
`

Webinar.Details.Pacman = styled.div`
  position: absolute;
  right: 2.4rem;
  top: -2.4rem;
  z-index: 1;
`

Webinar.Details.SemiRing = styled.div`
  position: absolute;
  left: -4rem;
  top: 4rem;
  z-index: 1;
`

Webinar.Description = styled.div`
  border-left: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  padding-left: 6rem;

  ${media.xl`
    border: 0;
    padding-left: 0;
  `}
`

Webinar.Documents = styled.div`
  margin: 2.4rem 0 ${({ withAdvert }) => (withAdvert ? '4rem' : '0')} 0;

  h5.ant-typography {
    font-size: 2rem;
  }

  .ant-table {
    border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
    border-radius: 0.8rem;
    margin-top: 1.6rem;

    .ant-table-thead {
      background-color: ${({ theme }) => theme.colors.gray[200]};

      .ant-table-cell {
        &:first-child {
          border-top-left-radius: 0.8rem;
        }

        &:last-child {
          border-top-right-radius: 0.8rem;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        &:last-child {
          .ant-table-cell {
            &:first-child {
              border-bottom-left-radius: 0.8rem;
            }

            &:last-child {
              border-bottom-right-radius: 0.8rem;
            }
          }
        }
      }
    }
  }
`

Webinar.Document = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 2.4rem 1.6rem 2.4rem;
  margin-top: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
  border-radius: 0.8rem;
`
Webinar.Document.Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.grayBlue[300]};
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
`

Webinar.Document.Name = styled(Webinar.Document.Title)`
  align-items: flex-start;
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.colors.gray[600]};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 1.4rem;
  line-height: 2rem;
  gap: 2.4rem;
  text-transform: none;
`

Webinar.Document.Download = styled.div`
  width: 100%;
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gray[400]};
`

Webinar.Download = styled.a`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-weight: 600;
`

Webinar.Download.Icon = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  background: url(${({ src }) => src}) no-repeat center / contain;
`

Webinar.Advertisement = styled.a`
  display: block;
  margin-top: 1.6rem;
`

Webinar.Advertisement.Picture = styled.img`
  width: 100%;
  border-radius: 0.8rem;
`

export { Webinar }
